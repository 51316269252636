import axios from 'axios'
import store from './store'


const subscribeUrl = store.state.backApiUrl + '/subscribe/'
const unisenderFunctions = {
    
    subscribe: async function subscribe(contactData) {

        const data = {
            email: contactData.email,
            phone: contactData.phone,
            fio: contactData.fio,
        }
        
        const resp = await axios.post(subscribeUrl, data);
        if (resp.data.result) {
            console.log("Успешная отправка email", resp.data);
            return
        }
        console.log("Ошибка при отправке email", resp.data);
    }

}

export const uniSubscribe = unisenderFunctions.subscribe

