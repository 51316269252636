<template>
    <div id="app">
        <template v-if="currentStep === 1">
            <StepOne ref="stepOne" @updateStepTwo="updateStepTwo()" :nightInfo="nightInfo" :scrollToTop="scrollToTop"
                :resetRoomIndex="resetRoomIndex" :getSpkConfig="getSpkConfig" />
        </template>
        <template v-if="currentStep === 2">
            <StepTwo :fillPhotos="fillPhotos" :fillRooms="fillRooms" :handlerRoomInfo="handlerRoomInfo"
                :choiceRoom="choiceRoom" :rooms="rooms" :otherRooms="otherRooms" :photos="photos" :thumbnails="thumbnails"
                :thumbnails2x="thumbnails2x" :roomIdx="roomIdx" :backendRooms="backendRooms"
                :backendTariffs="backendTariffs" :nightInfo="nightInfo" :scrollToTop="scrollToTop"
                :getWindowHeight="getWindowHeight" :decrementRoomIdx="decrementRoomIdx"
                :handlerCarouselClick="handlerCarouselClick" />
        </template>
        <template v-if="currentStep === 3">
            <StepThree :backendTariffs="backendTariffs" :scrollToTop="scrollToTop" :showMessage="showMessage" />
        </template>
        <template v-if="currentStep === 4">
            <StepFour :handlerRoomInfo="handlerRoomInfo" :scrollToTop="scrollToTop" @setSession="setSession()"
                @returnToStart="returnToStart(messageText)" ref="stepFour" :backendRooms="backendRooms"
                :showMessage="showMessage" :getSpkConfig="getSpkConfig" />
        </template>
        <InfoDrawer :expand="isInfoDrawer" @collapseInfoDrawer="isInfoDrawer = false" />
        <RoomMoreInfoDrawer v-if="isOpenRoomMoreInfo" :roomInfo="roomInfo" :guestParams="guestParams"
            :scrollToTop="scrollToTop" @collapseRoomMoreInfo="isOpenRoomMoreInfo = false" @syncOrder="syncOrder()"
            :key="isOpenRoomMoreInfo" :handlerCarouselClick="handlerCarouselClick" :choiceRoom="choiceRoom" />
        <template v-if="currentStep === 5">
            <div class="full-screen-preloader">
                <div class="wrapper">
                    <h2>Изменяем даты и загружаем свободные номера</h2>
                    <div class="lds-roller">
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>
                </div>
            </div>
        </template>

        <footer>
            Модуль онлайн-бронирования
            <a href="https://citportal.ru/booking" target="_blank">
                CIT PORTAL
            </a>
        </footer>
    </div>
</template>

<script>
import StepOne from "./components/StepOne.vue";
import StepTwo from "./components/StepTwo.vue";
import StepThree from "./components/StepThree.vue";
import StepFour from "./components/StepFour.vue";
import InfoDrawer from "./components/InfoDrawer.vue";
import RoomMoreInfoDrawer from "./components/RoomMoreInfoDrawer.vue";
import { mapGetters, mapActions, mapState } from "vuex";
import axios from "axios";
import store from "./store";
import { formatDateToUTC } from './api'
export default {
    name: "App",
    components: {
        StepOne,
        StepTwo,
        StepThree,
        StepFour,
        InfoDrawer,
        RoomMoreInfoDrawer,
    },
    data() {
        return {
            roomExpandIdx: -1,
            isMoreInfo: false,
            isInfoDrawer: false,
            moreInfoData: "",
            tariffs: this.$store.state.searchData.availableTariff,
            roomsData: this.$store.state.searchData.availableRoom,
            // roomsInfo: [],
            messageText:
                "Пока вы не пользовались модулем, возможно изменилась доступность номеров. Пожалуйста начните подбор номера заново.",
            isOpenRoomMoreInfo: false,
            rooms: [],
            roomInfo: { idx: 0, minPrice: 1000 },
            photos: {},
            thumbnails: {},
            thumbnails2x: {},
            roomIdx: 0,
            otherRooms: [],
            backendRooms: [],
            backendTariffs: [],


        };
    },
    mounted: async function () {
        const PAGE_CONTENT = "app";
        const SITE_URL = this.$store.state.parentSiteUrl;
        const moduleApp = document.getElementById(PAGE_CONTENT)

        document.addEventListener("DOMContentLoaded", function () {
            // Вторым параметром в postMessage можно передать '*' - тогда сообщение будет доступно для всех окон.
            parent.postMessage({ "iframeHeight": moduleApp.clientHeight }, SITE_URL);
        });

        const ro = new ResizeObserver(() => {
            parent.postMessage({ "iframeHeight": moduleApp.clientHeight }, SITE_URL);
        })
        ro.observe(moduleApp)

        //для приема сообщений от родительской страницы
        window.addEventListener("message", this.receiveMessage);

        await this.getBackendRooms()
        await this.getBackendTariffs();
        await this.fillPhotos()


    },
    computed: {
        ...mapGetters([
            "DAYS",
            "START_DATE",
            "END_DATE",
            "ROOMS_STATE",
            "TARIFF_STATE",
            "SESSION_URL",
            "PARENT_SITE_URL",
        ]),
        ...mapState([
            "step",
            "searchData",
            "backApiUrl",
            "guestParams",
            "days",
            //"backendRooms"
        ]),



        currentStep: function () {
            return this.step;
        },
    },
    methods: {
        ...mapActions([
            "SEARCH",
            "GET_CONFIG",
            "SET_SESSION",
            "SET_STEP",
            "SET_ADDED_ROOMS",
            "SET_SCROLL_TOP_DRAWER",
            "SET_DRAWER_MAX_HEIGHT",
            "SET_BACKEND_TARIFFS",
            "SET_BACKEND_ROOMS"
        ]),
        async startSearch() { },

        expandOrCollapseDesc(idx) {
            if (this.roomExpandIdx == idx) {
                this.roomExpandIdx = -1;
            } else {
                this.roomExpandIdx = idx;
            }
        },
        clickMoreInfo(info) {
            this.isMoreInfo = true;
            this.moreInfoData = info;
        },
        clickInfoDrawer() {
            this.isInfoDrawer = true;
        },

        async getSpkSession() {
            const resp = await axios(this.SESSION_URL, {
                method: "GET",
                headers: { "X-API-Key": this.$store.state.apiKey },
            });
            return resp.data.data;
        },
        async setSession() {
            const spkSession = await this.getSpkSession();
            this.SET_SESSION(spkSession);
        },
        returnToStart(message) {
            this.$alert(message, "", {
                confirmButtonText: "Начать заново",
                callback: () => {
                    this.SET_STEP(1);
                    this.$refs.stepFour.removeAllRooms()
                    let tmpGuestParams = this.guestParams
                    for (let idx = 0; idx < tmpGuestParams.length; idx++) {
                        tmpGuestParams[idx].adults.tariffIds = []
                        tmpGuestParams[idx].childs.tariffIds = []
                    }
                    setTimeout(this.SET_ADDED_ROOMS, 1000, null);
                },
            });
        },
        receiveMessage(event) {
            if (event.origin != this.$store.state.parentSiteUrl) {
                // что-то прислали с неизвестного домена - проигнорируем..

            }

            if (event.data.url) {
                this.$refs.stepOne.handleURLParams(event.data.url);
            }
            if (event.data.windowHeight) {
                let windowHeight = event.data.windowHeight;
                // this.$refs.stepOne.handleURLParams(event.data.url);
                console.log('Высота окна получена: ' + windowHeight);
                document.querySelector('.full-screen-preloader').style.minHeight = windowHeight + "px";
                document.querySelector('.full-screen-preloader').style.height = windowHeight + "px";
                // let sheet = window.document.styleSheets[0];
                // sheet.insertRule('.full-screen-preloader { min-height: ' + windowHeight + 'px; }', sheet.cssRules.length);
                // sheet.insertRule('.full-screen-preloader { height: ' + windowHeight + 'px; }', sheet.cssRules.length);

                const PAGE_CONTENT = "app";
                const SITE_URL = this.$store.state.parentSiteUrl;
                parent.postMessage(document.getElementById(PAGE_CONTENT).clientHeight, SITE_URL);
                this.scrollToTop();
            }
        },
        scrollToTop(top = 0) {
            const SITE_URL = this.$store.state.parentSiteUrl;
            parent.postMessage({ scrollToTop: top }, SITE_URL);
        },
        getWindowHeight() {
            const SITE_URL = this.$store.state.parentSiteUrl;
            parent.postMessage({ getWindowHeight: '' }, SITE_URL);
        },
        minTariffPrice(room, type = 'available') {
            let tariffs = room.guests[0].tariffs;
            let minTariff = 0
            if (tariffs.length > 0) {
                if (type == 'available') {
                    minTariff = tariffs[0].fullPrice;
                    for (let idx = 0; idx < tariffs.length; idx++) {
                        if (tariffs[idx].fullPrice < minTariff) {
                            minTariff = tariffs[idx].fullPrice;
                        }
                    }
                }
                if (type == 'other') {
                    minTariff = tariffs[0].dayPrice[0].price;
                    for (let idx = 0; idx < tariffs.length; idx++) {
                        if (tariffs[idx].dayPrice[0].price < minTariff) {
                            minTariff = tariffs[idx].dayPrice[0].price;
                        }
                    }
                }
            }
            return minTariff;
        },
        hasRestTariff(tariffs) {
            for (let idx = 0; idx < tariffs.length; idx++) {
                let currentTariff = tariffs[idx]
                let backendTariff = this.backendTariffs.filter((item) => {
                    return item.id_zdravnitsa === currentTariff.tariffId;
                });
                if (backendTariff.length > 0) {
                    if (backendTariff[0].rest) {
                        return true
                    }
                }


            }
            return false
        },
        hasRecoveryTariff(tariffs) {
            for (let idx = 0; idx < tariffs.length; idx++) {
                let currentTariff = tariffs[idx]
                let backendTariff = this.backendTariffs.filter((item) => {
                    return item.id_zdravnitsa === currentTariff.tariffId;
                });
                if (backendTariff.length > 0) {
                    if (!backendTariff[0].rest && !backendTariff[0].treatment) {
                        return true
                    }
                }

            }
            return false
        },
        async fillRooms(type = 'available') {
            let tmpRooms = [];
            for (let idx = 0; idx < this.searchData.length; idx++) {
                let roomItem = {}
                if (type == 'available') {
                    roomItem = this.searchData[idx].availableRoom
                }
                if (type == 'other') {
                    roomItem = this.searchData[idx].otherRoom
                }
                for (
                    let innerIdx = 0;
                    innerIdx < roomItem.length;
                    innerIdx++
                ) {
                    let currentRoom = roomItem[innerIdx];
                    let room = {
                        idx: idx,
                        id: currentRoom.id,
                        priority: 1000,
                        isLast: currentRoom.room == 1 ? true : false,
                        title: "",
                        description: "",
                        descZdrav: currentRoom.description,
                        area: "",
                        seats_number: "",
                        update_room1: "",
                        update_room2: "",
                        minPrice: null,
                        main_equipment: [],
                        equipment: [],
                        sales_leader: null,
                        otherDates: [],
                    };
                    if (type == 'available') {
                        room.minPrice = this.minTariffPrice(currentRoom)
                    }
                    if (type == 'other') {
                        let tmpOtherDates = []
                        for (let idx3 = 0; idx3 < currentRoom.otherSearch.length; idx3++) {
                            let otherDate = currentRoom.otherSearch[idx3]
                            let roomTariffs = currentRoom.guests[0].tariffs
                            let hasRecoveryTariff = this.hasRecoveryTariff(roomTariffs)
                            let hasRestTariff = this.hasRestTariff(roomTariffs)
                            let skipOtherDate = false
                            if (!hasRestTariff) {
                                let minRecoveryDays = this.$store.state.settings[0].min_recovery_days
                                if (otherDate.days < minRecoveryDays) {
                                    skipOtherDate = true
                                }
                                let minTreatmentDays = this.$store.state.settings[0].min_treatment_days
                                if (!hasRecoveryTariff && otherDate.days < minTreatmentDays) {
                                    skipOtherDate = true
                                }
                            }
                            let minPercentOtherDays = this.$store.state.settings[0].min_percent_other_days
                            let minRestDays = this.$store.state.settings[0].min_rest_days
                            if (otherDate.days >= this.days * minPercentOtherDays / 100 && otherDate.days >= minRestDays && !skipOtherDate) {
                                otherDate.date_in = otherDate.date_in.slice(0, 10)
                                let date_out = new Date(otherDate.date_in)
                                date_out.setDate(date_out.getDate() + otherDate.days)
                                otherDate.date_out = formatDateToUTC(date_out).slice(0, 10)
                                tmpOtherDates.push(otherDate)
                                if (tmpOtherDates.length == 3) {
                                    break
                                }
                            }
                        }
                        room.otherDates = tmpOtherDates
                        room.minPrice = this.minTariffPrice(currentRoom, type = 'other')
                        if (room.minPrice === 0) { continue }

                    }
                    if (type == 'other' && room.otherDates.length === 0) {
                        continue
                    }
                    tmpRooms.push(room);
                }
            }
            for (let idx = 0; idx < tmpRooms.length; idx++) {
                let currentRoom = tmpRooms[idx];
                let currentBackendRoom = this.backendRooms.filter((item) => {
                    return item.id_zdravnitsa === currentRoom.id;
                });

                if (currentBackendRoom.length > 0) {
                    currentRoom.title = currentBackendRoom[0].title;
                    currentRoom.description = currentBackendRoom[0].description;
                    currentRoom.area = currentBackendRoom[0].area;
                    currentRoom.seats_number =
                        currentBackendRoom[0].seats_number;
                    currentRoom.update_room1 =
                        currentBackendRoom[0].update_room1;
                    currentRoom.update_room2 =
                        currentBackendRoom[0].update_room2;
                    currentRoom.main_equipment =
                        currentBackendRoom[0].main_equipment;
                    currentRoom.equipment = currentBackendRoom[0].equipment;
                    currentRoom.sales_leader =
                        currentBackendRoom[0].sales_leader;
                    currentRoom.priority = currentBackendRoom[0].priority
                }
            }
            let roomSortedByPriority = tmpRooms.sort(function (a, b) {
                if (a.priority > b.priority) {
                    return 1;
                }
                if (a.priority < b.priority) {
                    return -1;
                }
                return 0;
            })
            if (type == 'available') {
                this.rooms = roomSortedByPriority;
                store.commit("SET_ROOMS", roomSortedByPriority)
            }
            if (type == 'other') {
                this.otherRooms = roomSortedByPriority;
                store.commit("SET_OTHER_ROOMS", roomSortedByPriority)

            }
        },
        async fillPhotos() {
            let photos = {};
            let thumbnails = {};
            let thumbnails2x = {};
            let backendRoom = null
            for (let idx = 0; idx < this.backendRooms.length; idx++) {
                backendRoom = this.backendRooms[idx]
                let tmpPhotos = [];
                let tmpThumbnails = [];
                let tmpThumbnails2x = [];
                let currentPhotos = await this.getRoomPhoto(backendRoom.id);
                for (let idx2 = 0; idx2 < currentPhotos.length; idx2++) {
                    tmpPhotos.push(currentPhotos[idx2].image);
                    tmpThumbnails.push(currentPhotos[idx2].thumbnail);
                    tmpThumbnails2x.push(currentPhotos[idx2].thumbnail_2x);
                }
                photos[backendRoom.id_zdravnitsa] = tmpPhotos;
                thumbnails[backendRoom.id_zdravnitsa] = tmpThumbnails;
                thumbnails2x[backendRoom.id_zdravnitsa] = tmpThumbnails2x;


            }
            this.photos = photos;
            this.thumbnails = thumbnails
            this.thumbnails2x = thumbnails2x

        },
        getBackendRoomByZdravId(id) {
            const rooms = this.backendRooms;
            for (let idx = 0; idx < rooms.length; idx++) {
                if (rooms[idx].id_zdravnitsa == id) {
                    return rooms[idx];
                }
            }
            return null;
        },
        async getRoomPhoto(roomId) {
            const url = `${this.backApiUrl}/photos/`;

            const resp = await axios(url, {
                method: "GET",
                params: { room_id: roomId },
            });
            if (resp.data) {
                return resp.data;
            }
            return null;
        },
        getRoomInfoById(id) {
            const bkRooms = this.backendRooms;
            const roomInfo = {
                title: "",
                description: "",
                numberOfSeats: null,
                area: null,
                photos: this.photos[id],
                minPrice: null,
                isLast: null,
                id: null,
                idx: null,
                main_equipment: [],
                equipment: [],
                salesLeader: null,
                type: null
            };
            if (bkRooms) {
                for (let idx = 0; idx < bkRooms.length; idx++) {
                    if (bkRooms[idx].id_zdravnitsa == id) {
                        roomInfo.description = bkRooms[idx].description;
                        roomInfo.numberOfSeats = bkRooms[idx].seats_number;
                        roomInfo.area = bkRooms[idx].area;
                        roomInfo.title = bkRooms[idx].title;
                        roomInfo.main_equipment = bkRooms[idx].main_equipment
                        roomInfo.equipment = bkRooms[idx].equipment
                        roomInfo.salesLeader = bkRooms[idx].sales_leader
                    }
                }
            }
            return roomInfo;
        },
        async handlerRoomInfo(roomId, type = "available", upsell = false, roomIdx) {
            let room = null
            if (type === "available") {
                room = this.rooms.filter((item) => {
                    return item.id === roomId;
                });

            }
            if (type == "other") {
                room = this.otherRooms.filter((item) => {
                    return item.id === roomId;
                });
            }
            this.scrollToTop()
            this.roomInfo = this.getRoomInfoById(roomId);
            this.roomInfo.minPrice = room[0].minPrice;
            this.roomInfo.isLast = room[0].isLast;
            this.roomInfo.id = roomId;
            this.roomInfo.idx = roomIdx;
            this.roomInfo.isActive = false
            this.roomInfo.type = type
            this.roomInfo.upsell = upsell
            this.isOpenRoomMoreInfo = true;

        },
        async choiceRoom(room, roomIdx, roomType = 'available') {

            let tmpGuestParams = this.guestParams
            tmpGuestParams[roomIdx].roomId = room.id;
            tmpGuestParams[roomIdx].descZdrav = room.descZdrav;
            await store.commit("SET_GUEST_PARAMS", tmpGuestParams);
            if (this.step != 4 && roomType == 'available') {
                this.showMessage(
                    `${1 + roomIdx}-й номер успешно выбран`,
                    "success"
                );
            }
            if (this.step === 2) {
                if (this.roomIdx === tmpGuestParams.length - 1) {
                    if (roomType == 'available') {
                        store.commit("SET_STEP", 3);
                    }
                    this.roomIdx = 0
                } else {
                    this.roomIdx++;
                }
            }
            this.isOpenRoomMoreInfo = false;
            this.scrollToTop();
        },
        showMessage(msg, msgType, customClass = '') {
            this.$message({
                message: msg,
                type: msgType,
                duration: 4000,
                // duration: 140000,
                customClass: customClass
                // offset: topOffSet
            });
        },
        syncOrder() {
            this.$refs.formRef.syncRoomsWithOrder()
            // this.$refs.F
        },
        async getBackendTariffs() {
            const url = `${this.backApiUrl}/tariffs/`;
            await axios
                .get(url)
                .then((resp) => {
                    this.backendTariffs = resp.data
                    return resp.data;
                })
                .catch((err) => {
                    console.log("ERROR_MESSAGE", err);
                });
        },
        async getBackendRooms() {
            const url = `${this.backApiUrl}/rooms/`;
            await axios
                .get(url)
                .then((resp) => {
                    // this.SET_BACKEND_ROOMS(resp.data);
                    this.backendRooms = resp.data
                    return resp.data;
                })
                .catch((err) => {
                    console.log("ERROR_MESSAGE", err);
                    this.isDataReady = false;
                });
        },
        nightInfo: function () {
            const nightQuantity = this.days;
            let nightTitle = "";
            if (nightQuantity === 1) {
                nightTitle = "ночь";
            } else if (nightQuantity < 5) {
                nightTitle = "ночи";
            } else {
                nightTitle = "ночей";
            }
            return `${nightQuantity} ${nightTitle}`;
        },
        resetRoomIndex() {
            this.roomIdx = 0
        },
        decrementRoomIdx() {
            this.roomIdx--
        },
        handlerCarouselClick(roomId, imageIndex) {
            this.currentRoomId = roomId;
            const SITE_URL = this.$store.state.parentSiteUrl;
            parent.postMessage(
                { roomPhotos: this.photos[roomId], imageIndex: imageIndex },
                SITE_URL
            );
        },
        async getSpkConfig() {
            const configUrl = this.$store.state.configUrl
            const session = this.$store.state.session
            let headers = { Authorization: `Bearer ${session}` }
            const resp = await axios(configUrl, {
                method: "GET",
                headers: headers,
            });
            return resp.data.data;
        }

    },
};
</script>

<style lang="css">
/* .el-drawer__wrapper {
	top: var(--scrollTopDrawer);
} */

/* .fade-enter-active,
.fade-leave-active {
	transition: opacity 0.5s;
}
.fade-enter,
.fade-leave-to {
	opacity: 0;
} */
</style>