var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('el-drawer',{attrs:{"size":"820px","visible":_vm.visible,"direction":_vm.direction,"before-close":_vm.handlerClose},on:{"update:visible":function($event){_vm.visible=$event}}},[_c('div',{staticClass:"room-full"},[_c('div',{staticClass:"room-full-top"},[_c('div',{staticClass:"column images"},[_c('div',{staticClass:"picture"},[_c('el-carousel',{attrs:{"autoplay":false,"height":"230px"}},_vm._l((_vm.roomInfo.photos),function(image,imageIndex){return _c('el-carousel-item',{key:imageIndex,staticClass:"images-wrapper"},[_c('div',{staticClass:"image",style:({
                                    backgroundImage: 'url(' + image + ')',
                                }),on:{"click":function($event){return _vm.handlerCarouselClick(_vm.roomInfo.id, imageIndex)}}})])}),1),_c('div',{staticClass:"nav"},_vm._l((_vm.roomInfo.photos),function(image,imageIndex){return _c('div',{key:imageIndex,staticClass:"image",style:({
                                backgroundImage: 'url(' + image + ')',
                            }),on:{"click":function($event){return _vm.handlerCarouselClick(_vm.roomInfo.id, imageIndex)}}})}),0),(_vm.roomInfo.salesLeader)?_c('div',{staticClass:"stickers"},[_c('div',{staticClass:"sticker"},[_vm._v("Лидер продаж")])]):_vm._e()],1)]),_c('div',{staticClass:"description column"},[_c('div',{staticClass:"description-content"},[_c('div',{staticClass:"title"},[_vm._v(" "+_vm._s(_vm.roomInfo.title)+" ")]),_c('div',{staticClass:"properties"},[(_vm.roomInfo.isLast)?_c('div',{staticClass:"last-room"},[_c('span',[_vm._v("Остался 1 номер")])]):_vm._e(),_c('div',[_c('i',{staticClass:"las la-ruler-combined"}),_c('span',[_vm._v(_vm._s(_vm.roomInfo.area)+" м²")])]),_c('div',[_c('i',{staticClass:"las la-user-friends"}),_c('span',[_vm._v("Вместимость до "+_vm._s(_vm.roomInfo.numberOfSeats)+" мест")])]),_c('div',[_c('i',{staticClass:"las la-baby"}),_c('span',[_vm._v("Детям до 4 лет можно без отдельного спального места")])])]),_c('div',{staticClass:"footer",class:{
                            'active-card':
                                _vm.guestParams[_vm.roomInfo.idx].roomId == _vm.roomInfo.id && !_vm.roomInfo.upsell
                        }},[_c('div',{staticClass:"price"},[_c('div',{staticClass:"price-numbers"},[_c('div',{staticClass:"actual-price"},[_vm._v(" от "),_c('b',[_vm._v(_vm._s(_vm.roomPrice))]),_vm._v(" ₽ ")])])]),(_vm.roomInfo.type=='available')?_c('el-button',{staticClass:"choose",attrs:{"type":"primary"},on:{"click":_vm.choiceRoomFromDrawer}},[_vm._v("Выбрать номер")]):_vm._e(),_c('el-button',{staticClass:"choosen",attrs:{"type":"primary"},on:{"click":function($event){return _vm.goToTheNextStep()}}},[_c('i',{staticClass:"las la-check"}),_vm._v(" Далее ")])],1)])])]),_c('div',{staticClass:"room-full-bottom"},[_c('div',{staticClass:"column"},[_c('ul',{staticClass:"features"},_vm._l((_vm.roomInfo.equipment),function(item,index){return _c('li',{key:index},[_c('i',{staticClass:"las la-check"}),_vm._v(_vm._s(item))])}),0)]),_c('div',{staticClass:"column"},[_c('div',{domProps:{"innerHTML":_vm._s(_vm.roomInfo.description)}})]),_c('div',{staticClass:"full-card-bottom-footer",class:{
                    'active-card':
                        _vm.guestParams[_vm.roomInfo.idx].roomId == _vm.roomInfo.id  && !_vm.roomInfo.upsell,
                }},[_c('div',{staticClass:"link",on:{"click":_vm.handlerClose}},[_vm._v(" Закрыть описание ")]),(_vm.roomInfo.type=='available')?_c('el-button',{staticClass:"choose",attrs:{"type":"primary"},on:{"click":_vm.choiceRoomFromDrawer}},[_vm._v(" Выбрать номер")]):_vm._e(),_c('el-button',{staticClass:"choosen",attrs:{"type":"primary"},on:{"click":function($event){return _vm.goToTheNextStep()}}},[_c('i',{staticClass:"las la-check"}),_vm._v(" Далее ")])],1)])])])}
var staticRenderFns = []

export { render, staticRenderFns }