<template>
    <div class="step-3">
        <header>
            <div class="top-header">
                <div class="header-back" @click="SET_STEP(2)">
                    <i class="el-icon-arrow-left"></i>
                    <span>Назад к номерам</span>
                </div>

                <div class="header-dates" @click="SET_STEP(1)">
                    <i class="las la-calendar-week"></i>
                    <span
                        >{{ formatedDate(START_DATE) }} -
                        {{ formatedDate(END_DATE) }}
                        ({{ NIGHTS_INFO }})
                    </span>
                </div>

                <div class="header-room" @click="SET_STEP(1)">
                    <i class="las la-user"></i>
                    <span>{{ BOOKING_INFO }}</span>
                </div>
            </div>

            <el-progress :percentage="75" :format="format()"></el-progress>
        </header>

        <div class="step-heading">
            <h2>Выберите путевку для каждого гостя</h2>
        </div>
        <div
            class="room-vouchers"
            v-for="(item, idx) in guestParams"
            :key="idx"
        >
            <h3 :id="'room-title' + idx" class="room-heading" v-if="guestParams.length > 1">
                Номер {{ idx + 1 }}
            </h3>
            <el-tabs
                class="guest-tabs"
                type="border-card"
                :activeName="activeTabs[idx]"
                :key="tabKey"
            >
                <el-tab-pane
                    :label="num + '-й взрослый'"
                    v-for="num in item.adults.quantity"
                    :key="num"
                    :name="createTabName(idx, num)"
                    :id="'guest' + idx"
                >
                    <div class="voucher-category">
                        <!-- <h3>Категории путевок</h3> -->
                        <CategoryFilterRadio
                            @changeCategory="changeCategoryFilter($event)"
                            @rerenderCards="rerenderComponent()"
                            :roomNumber="idx"
                            :sequenceNumber="num"
                            :value="categoryFilter[idx][num - 1]"
                            :categories="getCategories(idx, num, item)"
                        />
                    </div>

                    <div
                        class="tariff-cards"
                        v-for="tariff in tariffsFiltred(
                            idx,
                            item.adults.isDop[num - 1]
                        )"
                        :key="tariff.id"
                    >
                        <TariffCard
                            v-for="(
                                tariffItem, tariffIdx
                            ) in tariff.tariffs"
                            :key="tariffIdx"
                            :tabName="createTabName(idx, num)"
                            :tariff="tariffItem"
                            :roomNumberIdx="idx"
                            :roomId="tariff.roomId"
                            :guestAge="tariff.guestAge"
                            :categoryFilter="categoryFilter[idx][num - 1]"
                            :currentCategoryFilter="currentCategoryFilter"
                            @choiceTariff="
                                chooseTariff(
                                    $event,
                                    createTabName(idx, num),
                                    idx
                                )
                            "
                            @unChoiceTariff="
                                unChoiceTariff($event, idx, tariff.guestAge)
                            "
                            @clickInfoDrawer="clickInfoDrawer"
                            @clickMoreInfo="clickMoreInfo($event)"
                        />
                    </div>
                </el-tab-pane>

                <el-tab-pane
                    v-for="(age, index) in item.childs.ages"
                    :key="index + 100"
                    :label="childInfo(age)"
                    :name="createTabName(idx, index, 1)"
                >
                    <div class="voucher-category">
                        <!-- <h3>Категории путевок</h3> -->
                        <CategoryFilterRadio
                            @changeCategory="changeCategoryFilter($event)"
                            @rerenderCards="rerenderComponent()"
                            :roomNumber="idx"
                            :sequenceNumber="item.adults.quantity + index + 1"
                            :categories="getCategories(idx, index, item, adult=false)"
                            :value="categoryFilter[idx][item.adults.quantity + index]"
                        />
                    </div>
                    
                    <div
                        class="tariff-cards"
                        v-for="tariff in tariffsFiltred(
                            idx,
                            item.childs.isDop[index],
                            age
                        )"
                        :key="tariff.id"
                    >
                        <TariffCard
                            v-for="(
                                tariffItem, tariffIdx
                            ) in tariff.tariffs"
                            :key="tariffIdx"
                            :tabName="createTabName(idx, index, 1)"
                            :tariff="tariffItem"
                            :roomNumberIdx="idx"
                            :roomId="tariff.roomId"
                            :guestAge="tariff.guestAge"
                            :categoryFilter="
                                categoryFilter[idx][
                                    item.adults.quantity + index
                                ]
                            "
                            :currentCategoryFilter="currentCategoryFilter"
                            @choiceTariff="
                                chooseTariff(
                                    $event,
                                    createTabName(idx, index, 1),
                                    idx
                                )
                            "
                            @clickInfoDrawer="clickInfoDrawer"
                            @clickMoreInfo="clickMoreInfo($event)"
                            :goToNextTab="goToNextTab"
                        />
                    </div>
                </el-tab-pane>
            </el-tabs>
        </div>

        <VoucherMoreInfoDrawer
            v-if="isMoreInfo"
            :expand="isMoreInfo"
            @collapseMoreInfo="isMoreInfo = false"
            :info="moreInfoData"
            @chooseTariff="chooseTariff(moreInfoData, currentTab, currentIdx)"
            @unChoiceTariff="
                unChoiceTariff($event.tabName, $event.idx, $event.guestAge)
            "
            :goToNextTab="goToNextTab"
            :scrollToTop="scrollToTop"
        />

        <div class="footer-info">
            Не нашли интересующий вас вариант? Позвоните нам по телефону
            <a href="tel:8-800-707-5182">8 800 707 5182</a>, мы поможем.
        </div>
    </div>
</template>

<script>
import CategoryFilterRadio from "./CategoryFilterRadio.vue";
import VoucherMoreInfoDrawer from "./VoucherMoreInfoDrawer.vue";
import TariffCard from "./TariffCard.vue";
import { mapActions, mapGetters } from "vuex";
import { formatDate, formatProgressBar } from "../api.js";
// import { scroller } from "vue-scrollto/src/scrollTo";

export default {
    components: {
        CategoryFilterRadio,
        VoucherMoreInfoDrawer,
        TariffCard,
		
    },
    props: {
		backendTariffs: Array,
		scrollToTop: Function,
		showMessage: Function
	},
    data() {
        return {
            guestParams: this.$store.state.guestParams,
            guests: this.$store.state.guests,
            isMoreInfo: false,
            isInfoDrawer: false,
            moreInfoData: {'tariff':{'discount': 0}},
            choosenTariffs: [],
            choosenTabs: [],
            activeTabs: ["101", "201", "301", "401", "501"],
            tabKey: "",
            tariffs: [],
            tabNames: [],
            categoryFilter: [],
            currentCategoryFilter: "",
            currentTab: null,
            currentIdx: null,

            // startScrollTo: scroller(),
        };
    },
    computed: {
        ...mapGetters([
            "START_DATE",
            "END_DATE",
            "DAYS",
            "TARIFFS",
            "BOOKING_INFO",
            "NIGHTS_INFO",
            "CHOOSEN_ROOMS",
            "BACK_API_URL",
            "ROOMS",
            "SEARCH_DATA",
            "GUESTS",
        ]),
    },
    created: async function () {
        this.fillTarifs();
        //this.initChoosenTabs();
        //this.initActiveTabsName();
        this.activeTabs;
        //this.getBackendTariffs();
        this.fillDops();
        this.initCategoryFilter();
    },
    mounted: function () {
        this.scrollToTop();
    },
    methods: {
        ...mapActions([
            "SET_STEP",
            "SET_TARIFFS",
            "SET_GUEST_PARAMS",
        ]),
        formatedDate(Date) {
            return formatDate(Date);
        },
        format() {
            return formatProgressBar;
        },
        clickMoreInfo(info) {
            // this.$refs.voucherMoreInfo.$props.expand = true
            // this.$refs.voucherMoreInfo.$props.info = info
            this.moreInfoData = info;
            this.currentTab = info.tabName;
            this.currentIdx = info.idx;
            this.isMoreInfo = true;
        },
        childInfo(age) {
            if (age >= 5) {
                return `Ребёнок ${age} лет`;
            } else if (age >= 2) {
                return `Ребёнок ${age} года`;
            } else if (age === 1) {
                return `Ребёнок ${age} год`;
            }
            return `Ребёнок до года`;
        },
        isTariffChoosen(roomIdx, tabName) {
            let isChoosen = false;
            for (
                let idx = 0;
                idx < this.guestParams[roomIdx].adults.tariffIds.length;
                idx++
            ) {
                let item = this.guestParams[roomIdx].adults.tariffIds[idx];

                if (item.tabName == tabName) {
                    isChoosen = true;
                    return isChoosen;
                }
            }
            for (
                let idx = 0;
                idx < this.guestParams[roomIdx].childs.tariffIds.length;
                idx++
            ) {
                let item = this.guestParams[roomIdx].childs.tariffIds[idx];
                if (item.tabName == tabName) {
                    isChoosen = true;
                    return isChoosen;
                }
            }

            return isChoosen;
        },
        chooseTariff(tariff, tabName, idx) {
            const tariffObj = {
                tabName: tabName,
                id: tariff.tariff.tariffId,
                isRoom: tariff.tariff.isRoom,
                price: tariff.tariff.fullPrice,
            };
            let tariffIds = [];
            //let prices = []
            const tariffIsChoosen = this.isTariffChoosen(
                idx,
                tariffObj.tabName,
                tariffObj.id,
                tariffObj.isRoom
            );
            if (tariffIsChoosen) {
                this.unChoiceTariff(tabName, idx, tariff.guestAge);
            }
            let guestParams = this.guestParams[idx];
            if (tariff.guestAge == -1) {
                tariffIds = guestParams.adults.tariffIds;
                //prices = guestParams.adults.prices
                if (tariffIds.length < guestParams.adults.quantity) {
                    tariffIds.push(tariffObj);
                    //prices.push(tariff.tariff.fullPrice)
                }
            } else {
                tariffIds = guestParams.childs.tariffIds;
                //prices = guestParams.childs.prices
                if (tariffIds.length < guestParams.childs.ages.length) {
                    tariffIds.push(tariffObj);
                    //prices.push(tariff.tariff.fullPrice)
                }
            }
            this.guestParams[idx] = guestParams;
            this.SET_GUEST_PARAMS(this.guestParams);
            this.goToNextTab(tabName, idx)
            // тутже делаем подкат
			let topMessage = 20;
			let elementIdMessage = '';
			let current = document.getElementById('tab-' + tabName);
			let nextSibling = current.nextElementSibling;
			if (nextSibling) {
				elementIdMessage = "room-title" + (idx);
			} else {
				// console.log('nextSibling not exist');
				elementIdMessage = "room-title" + (idx + 1);
			}
			
			// console.log('idx: ' + idx);
			let elementMessage = document.getElementById(elementIdMessage);
			if (elementMessage) {
				topMessage = elementMessage.offsetTop;
			}
			let customClass = 'stepThreeMessage';

			this.showMessage(`Путевка успешно выбрана`, "success", customClass);
			setTimeout(function(){
				document.querySelector('.stepThreeMessage').style.top = topMessage + "px";
			},50);
		},

        removeTariff(tariffs, tabName) {
            for (let idx = 0; idx < tariffs.length; idx++) {
                let item = tariffs[idx];
                if (item.tabName == tabName) {
                    tariffs.splice(idx, 1);
                }
            }
            return tariffs;
        },
        unChoiceTariff(tabName, roomIdx, age) {
            let choosenTariffs = [];
            if (age === -1) {
                choosenTariffs = this.guestParams[roomIdx].adults.tariffIds;
                let tmp = this.removeTariff(choosenTariffs, tabName);
                this.guestParams[roomIdx].adults.tariffIds = tmp;
            } else {
                choosenTariffs = this.guestParams[roomIdx].childs.tariffIds;
                this.guestParams[roomIdx].childs.tariffIds = this.removeTariff(
                    choosenTariffs,
                    tabName
                );
            }

            this.SET_GUEST_PARAMS(this.guestParams);
        },
        createTabName(idx1, idx2, addendum = 0) {
            idx1 += 1;
            let tabName = `${idx1}${addendum}${idx2}`;
            if (this.tabNames.indexOf(tabName) == -1) {
                this.tabNames.push(tabName);
            }
            return tabName;
        },
        initActiveTabsName() {
            let activeTab = "";
            for (let idx = 0; idx < this.guestParams.length; idx++) {
                activeTab = idx + 1 + "1";
                this.activeTabs.push(Object.keys(activeTab).toString());
            }
        },
        isAllTariffsChoosen() {
            
            let choosenTariffsCount = 0;
            for (let idx = 0; idx < this.guestParams.length; idx++) {
                choosenTariffsCount +=
                    this.guestParams[idx].adults.tariffIds.length +
                    this.guestParams[idx].childs.tariffIds.length;
            }
            if (choosenTariffsCount == this.getGuestsCount()) {
                return true;
            }

            return false;
        },
        
        clickInfoDrawer() {
            this.isInfoDrawer = true;
        },
        
        fillTarifs() {
            const searchData = this.SEARCH_DATA;
            let tmpTariffs = [];
            for (let idx = 0; idx < searchData.length; idx++) {
                const availableRooms = searchData[idx].availableRoom;
                for (
                    let innerIdx = 0;
                    innerIdx < availableRooms.length;
                    innerIdx++
                ) {
                    const room = availableRooms[innerIdx];
                    if (room.id === this.guestParams[idx].roomId) {
                        for (
                            let interiorIdx = 0;
                            interiorIdx < room.guests.length;
                            interiorIdx++
                        ) {
                            let guests = room.guests[interiorIdx];
                            let tariff = {
                                idx: idx,
                                roomId: room.id,
                                guestAge: guests.age,
                                isDop: guests.isDop,
                                tariffs: guests.tariffs,
                                zdravPriority: guests.age === -1 ? 0 : (idx * 10 + this.guestParams[idx].childs.ages.indexOf(guests.age))
                            };
                            tmpTariffs.push(tariff);
                        }
                    }
                }
            }
            for (let idx = 0; idx < tmpTariffs.length; idx++) {
                let tariffs = tmpTariffs[idx].tariffs;
                for (let innerIdx = 0; innerIdx < tariffs.length; innerIdx++) {
                    let tariff = tariffs[innerIdx];
                    let backendTariff = this.backendTariffsFiltredById(
                        tariff.tariffId
                    );
                    if (backendTariff.length > 0) {
                        tariff.title = backendTariff[0].title;
                        tariff.description = backendTariff[0].description;
                        tariff.treatment = backendTariff[0].treatment;
                        tariff.food = backendTariff[0].food;
                        tariff.cancel_reservaton =
                            backendTariff[0].cancel_reservaton;
                        tariff.payment = backendTariff[0].payment;
                        tariff.special_offer = backendTariff[0].special_offer;
                        tariff.rest = backendTariff[0].rest;
                        tariff.best_price = backendTariff[0].best_price;
                        tariff.cashbackMir = backendTariff[0].cashback_mir;
                        tariff.discount = backendTariff[0].discount;
                        
                    }
                }
            }
            let tariffSortedByPriority = tmpTariffs.sort(function (a, b) {
                if (a.zdravPriority > b.zdravPriority) {
                    return 1;
                }
                if (a.zdravPriority < b.zdravPriority) {
                    return -1;
                }
                return 0;
            })
            this.tariffs = tariffSortedByPriority;

            this.SET_TARIFFS(tariffSortedByPriority);
        },
        fillDops() {
            for (let idx = 0; idx < this.guestParams.length; idx++) {
                let item = this.guestParams[idx];
                item.adults.isDop = [];
                //item.adults.prices = []
                item.childs.isDop = [];
                //item.childs.prices = []
                for (
                    let innerIdx = 0;
                    innerIdx < this.tariffs.length;
                    innerIdx++
                ) {
                    let tariff = this.tariffs[innerIdx];
                    if (tariff.idx != idx) {
                        continue;
                    }
                    if (tariff.guestAge == -1) {
                        item.adults.isDop.push(tariff.isDop);
                    } else {
                        item.childs.isDop.push(tariff.isDop);
                    }
                }
            }
        },
        backendTariffsFiltredById(id) {
            let tmpTariffs = this.backendTariffs;
            tmpTariffs = tmpTariffs.filter((item) => {
                return item.id_zdravnitsa === id;
            });
            return tmpTariffs;
        },
        tariffsFiltred(idx, isDop, age=-1) {
            let tmpTariffs = this.tariffs;
            tmpTariffs = tmpTariffs.filter((item) => {
                return item.idx === idx && item.isDop === isDop && item.guestAge === age;
            });
            //убираем дубли
            for (let i = 0; i < tmpTariffs.length; i++) {
                let tariff = tmpTariffs[i];
                let hash =
                    tariff.guestAge.toString() +
                    tariff.tariffs[0].tariffId.toString();
                for (
                    let innerIdx = i + 1;
                    innerIdx < tmpTariffs.length;
                    innerIdx++
                ) {
                    let innerTariff = tmpTariffs[innerIdx];
                    let innerHash =
                        innerTariff.guestAge.toString() +
                        innerTariff.tariffs[0].tariffId.toString();
                    if (hash === innerHash) {
                        tmpTariffs.splice(innerIdx, 1);
                    }
                }
            }
            return tmpTariffs;

            // let tmpTariffs = this.tariffs;
            // tmpTariffs = tmpTariffs.filter((item) => {
            //     return item.idx === idx && item.isDop === isDop && item.guestAge == age;
            // });
            
        },
        getActiveTabName(idx) {
            return this.activeTabs[idx];
        },
        getGuestsCount() {
            let count = 0;
            for (let idx = 0; idx < this.guests.length; idx++) {
                count += this.guests[idx];
            }
            return count;
        },
        log(msg) {
            console.log("LOG", msg);
        },
        scrollToElement(idx) {
			let top = 0;
			let elementId = "room-title" + (idx + 1);
			let element = document.getElementById(elementId);
			if (element) {
				top = element.offsetTop - 20;
			}
			this.scrollToTop(top);
        },
        initCategoryFilter() {
            let matrix = [];
            for (let idx = 0; idx < this.guestParams.length; idx++) {
                let item = this.guestParams[idx];
                matrix[idx] = [];
                for (let idx2 = 0; idx2 < item.adults.quantity; idx2++) {
                    matrix[idx][idx2] = "all";
                }
                for (let idx3 = 0; idx3 < item.childs.ages.length; idx3++) {
                    matrix[idx][idx3 + item.adults.quantity] = "all";
                }
            }
            this.categoryFilter = matrix;
        },
        changeCategoryFilter(params) {
            this.categoryFilter[params.roomNumber][params.sequenceNumber - 1] =
                params.value;
            this.currentCategoryFilter =
                params.roomNumber + "_" + params.sequenceNumber;
        },
        rerenderComponent() {
            this.$forceUpdate();
        },
        goToNextTab(tabName, idx) {
            const indexOfTab = this.tabNames.indexOf(tabName);
            if (indexOfTab < this.tabNames.length - 1) {
                const nextTabName = this.tabNames[indexOfTab + 1];
                const firstCharTabName = nextTabName[0];
                this.activeTabs[Number(firstCharTabName) - 1] = nextTabName;
                this.tabKey = Math.random();
                if (idx < this.guestParams.length - 1 && (Number(firstCharTabName) - 1 > idx)) {
                    this.scrollToElement(idx);
                } else {
                    this.scrollToElement(idx - 1);
                }
            } else if (this.isAllTariffsChoosen()) {
                this.SET_STEP(4);
            }
        },
        getCategories(roomIdx, tabIdx, guestParamItem, adult=true) {
            let categories = {}
            let rest = false
            let special_offer = false
            let treatment = false
            let recovery = false
            let tariffs = []
            if(adult) {
                tariffs = this.tariffsFiltred(roomIdx, guestParamItem.adults.isDop[tabIdx - 1])
            }else{
                tariffs = this.tariffsFiltred(roomIdx, guestParamItem.childs.isDop[tabIdx], guestParamItem.childs.ages[tabIdx])
            }
            tariffs.forEach(element => {
                element.tariffs.forEach(item => {
                    if(item.rest){
                        rest = true
                    }
                    if(item.special_offer){
                        special_offer = true
                    }
                    if(item.treatment){
                        treatment = true
                    } else {
                        if(!item.rest){
                            recovery = true
                        }
                    }
                    

                }
                )
            });
            categories = {
                "rest": rest,
                "special_offer": special_offer,
                "treatment": treatment,
                "recovery": recovery
            }
            return categories
        }
    },
};
</script>
