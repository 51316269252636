<template>
    <el-drawer
        size="820px"
        :visible.sync="this.expand"
        :direction="direction"
        :before-close="handleClose"
    >
		<div class="drawer-content" v-if="info">
			<h2> {{ info.title }}</h2>
			<div v-html="info.description"> </div>
			<!-- <h4>Что входит</h4>
			<ul>
				<li>Lorem ipsum dolor sit amet</li>
				<li>Consectetur adipisicing elit</li>
				<li>Sed do eiusmod tempor incididunt</li>
				<li>Ut labore et dolore magna aliqua</li>
			</ul>

			<h4>Описание</h4>

			<p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Cum accusantium odit enim corrupti nesciunt non ut. Quae, quia rem tenetur dignissimos veritatis eaque et, quas, corporis consectetur non reprehenderit ab.</p>

			<p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Cum accusantium odit enim corrupti nesciunt non ut. Quae, quia rem tenetur dignissimos veritatis eaque et, quas, corporis consectetur non reprehenderit ab.</p>

			<p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Cum accusantium odit enim corrupti nesciunt non ut. Quae, quia rem tenetur dignissimos veritatis eaque et, quas, corporis consectetur non reprehenderit ab.</p>

			<h4>Требования</h4>

			<p>При покупке путевки обязательно наличие санаторно-курортной карты, справки об эпидокружении и выписного эпикриза из истории болезни/амбулаторной карты.</p>

			<h4>Условия</h4>

			<ul>
				<li>Lorem ipsum dolor sit amet</li>
				<li>Consectetur adipisicing elit</li>
				<li>Sed do eiusmod tempor incididunt</li>
				<li>Ut labore et dolore magna aliqua</li>
			</ul>

			<h4>Список</h4>

			<ul>
				<li>Lorem ipsum dolor sit amet</li>
				<li>Consectetur adipisicing elit</li>
				<li>Sed do eiusmod tempor incididunt</li>
			</ul> -->
		</div>

    </el-drawer>
</template>

<script>
export default {
    props: {
        expand: Boolean,
		info: Object
    },
    data() {
        return {
            direction: "rtl",
        };
    },
    methods: {
        handleClose() {
            this.$emit("collapseInfoDrawer");
        },
    },
};
</script>
