import Vue from 'vue'
import App from './App.vue'
import store from './store'
import Vuelidate from 'vuelidate'
import './plugins/element.js'
import './assets/style.sass'

Vue.config.productionTip = false
Vue.use(Vuelidate);

new Vue({
  store,
  Vuelidate,
  render: h => h(App)
}).$mount('#app')
