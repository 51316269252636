<template>
    <div>
        <div class="form-line checkbox-line" v-if="isAdult">
            <el-checkbox v-model="checked"
                @change="copyPayerFio" @click="copyPayerFio">ФИО совпадает с плательщиком</el-checkbox
            >
        </div>

        <div class="form-line fio-line">
            <el-form-item prop="secondName">
                <el-input
                    placeholder="Фамилия"
                    v-model.trim="locSecondName"
                    v-on:input="inpt"
                ></el-input>
            </el-form-item>
            <el-form-item prop="name">
                <el-input
                    placeholder="Имя"
                    v-model.trim="locName"
                    v-on:input="inpt"
                ></el-input>
            </el-form-item>
            <el-form-item prop="thirdName">
                <el-input
                    placeholder="Отчество"
                    v-model.trim="locThirdName"
                    v-on:input="inpt"
                ></el-input>
            </el-form-item>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        pSecondName: String,
        pName: String,
        pThirdName: String,
        isAdult: Boolean,
        roomIdx: Number,
        guestIdx: Number,
        secondName: String,
        name: String,
        thirdName: String,
    },
    data() {
        return {
            locSecondName: this.secondName,
            locName: this.name,
            locThirdName: this.thirdName,
            checked: false,
        };
    },
    
    methods: {
        selectChanged(value) {
            this.$emit("selectChanged", value);
        },
        copyPayerFio() {
            if (this.checked) {
                this.locSecondName = this.pSecondName
                this.locName = this.pName
                this.locThirdName = this.pThirdName
                this.$emit('input', {
                    "secondName":this.locSecondName,
                    "name": this.locName,
                    "thirdName": this.locThirdName,
                    "idx": this.roomIdx,
                    "idx2": this.guestIdx
                    })
                
            }
        },
        inpt() {
            this.$emit('input', {
                    "secondName":this.locSecondName,
                    "name": this.locName,
                    "thirdName": this.locThirdName,
                    "idx": this.roomIdx,
                    "idx2": this.guestIdx
                    })
            this.$emit('input', {"secondName":this.locSecondName , "name": this.locName, "thirdName": this.locThirdName, "idx": this.roomIdx, "idx2": this.guestIdx})

        }
    },
};
</script>
