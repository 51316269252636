import axios from 'axios'
import store from './store'


const backendUrl = store.state.backApiUrl


const apiFunctions = {
     
    getRoomsInfo: async function getRoomsInfo() {
        const resp = await axios(backendUrl + 'rooms/', { method: 'GET' })
        return resp.data
    },
    formatProgressBar: function () {
        if (this.$store.state.step === 2) {
            return "2/4";
        } else if (this.$store.state.step === 3) {
            return "3/4";
        }
        return "4/4";
    },
    formatDate: function (inputDate) {
        const date = new Date(inputDate);
        const month = date.getMonth();
        const day = date.getDate();

        if (month === 0) {
            return `${day} января`;
        } else if (month === 1) {
            return `${day} февраля`;
        } else if (month === 2) {
            return `${day} марта`;
        } else if (month === 3) {
            return `${day} апреля`;
        } else if (month === 4) {
            return `${day} мая`;
        } else if (month === 5) {
            return `${day} июня`;
        } else if (month === 6) {
            return `${day} июля`;
        } else if (month === 7) {
            return `${day} августа`;
        } else if (month === 8) {
            return `${day} сентября`;
        } else if (month === 9) {
            return `${day} октября`;
        } else if (month === 10) {
            return `${day} ноября`;
        } else {
            return `${day} декабря`;
        }
    },
    formatDateToUTC: function (date, incrementMonth=true) {
        let days = date.getDate()
            if (days < 10) {
                days = `0${days}`
            }
            let month = date.getMonth()
            if (incrementMonth) {
                month++
            }
            if (month < 10) {
                month = `0${month}`
            }
            return `${date.getFullYear()}-${month}-${days}T00:00:00.000Z`

    }
}
export const getRoomsInfo = apiFunctions.getRoomsInfo
export const formatProgressBar = apiFunctions.formatProgressBar
export const formatDate = apiFunctions.formatDate
export const formatDateToUTC = apiFunctions.formatDateToUTC

