<template>
    <el-form :model="contactForm" ref="contactForm" class="demo-dynamic">
        <div class="form-fields-group">
            <h2 id="formFirstLine">Укажите ФИО и контакты плательщика</h2>
            <div class="form-line fio-line">
                <el-form-item prop="secondName">
                    <el-input
                        placeholder="Фамилия"
                        v-model.trim="contactForm.payerSecondName"
                        @blur="$v.contactForm.payerSecondName.$touch()"
                        :class="{
                            'el-form-error':
                                $v.contactForm.payerSecondName.$error,
                        }"
                    ></el-input>
                    <div v-if="$v.contactForm.payerSecondName.$error">
                        <div
                            class="el-form-item__error"
                            v-if="!$v.contactForm.payerSecondName.required"
                        >
                            Поле должно быть заполнено
                        </div>
                        <div
                            class="el-form-item__error"
                            v-if="!$v.contactForm.payerSecondName.minLength"
                        >
                            Поле должно содержать больше одного символа
                        </div>
                    </div>
                </el-form-item>

                <el-form-item prop="name">
                    <el-input
                        placeholder="Имя"
                        v-model.trim="contactForm.payerName"
                        :class="{
                            'el-form-error': $v.contactForm.payerName.$error,
                        }"
                    ></el-input>
                    <div v-if="$v.contactForm.payerName.$error">
                        <div
                            class="el-form-item__error"
                            v-if="!$v.contactForm.payerName.required"
                        >
                            Поле должно быть заполнено
                        </div>
                        <div
                            class="el-form-item__error"
                            v-if="!$v.contactForm.payerName.minLength"
                        >
                            Поле должно содержать больше одного символа
                        </div>
                    </div>
                </el-form-item>

                <el-form-item prop="thirdName">
                    <el-input
                        placeholder="Отчество"
                        v-model.trim="contactForm.payerThirdName"
                    ></el-input>
                </el-form-item>
            </div>
            <div class="form-line with-icon">
                <div class="field">
                    <i class="las la-envelope"></i>
                    <el-form-item prop="email">
                        <el-input
                            placeholder="Email"
                            v-model.trim="contactForm.email"
                            @blur="$v.contactForm.email.$touch()"
                            :class="{
                                'el-form-error': $v.contactForm.email.$error,
                            }"
                        ></el-input>
                        <div v-if="$v.contactForm.email.$error">
                            <div
                                class="el-form-item__error"
                                v-if="!$v.contactForm.email.required"
                            >
                                Поле должно быть заполнено
                            </div>
                            <div
                                class="el-form-item__error"
                                v-if="!$v.contactForm.email.email"
                            >
                                Введите корректный Email
                            </div>
                        </div>
                    </el-form-item>
                </div>

                <div class="label-block">
                    Мы вышлем вам подтверждение бронирования на этот адрес
                </div>
            </div>

            <div class="form-line with-icon">
                <div class="field">
                    <i class="las la-phone"></i>
                    <el-form-item prop="phone">
                        <el-input
                            placeholder="Телефон"
                            v-model="contactForm.phone"
                            type="tel"
                            clearable
                            @input="inputPhone($event)"
                            @clear="clearPhone"
                            @blur="$v.contactForm.phone.$touch()"
                            :class="{
                                'el-form-error': $v.contactForm.phone.$error,
                            }"
                        ></el-input>
                        <div v-if="$v.contactForm.phone.$error">
                            <!-- <div class="el-form-item__error" v-if="!$v.contactForm.phone.required">Поле должно быть заполнено</div> -->
                            <div
                                class="el-form-item__error"
                                v-if="!$v.contactForm.phone.validFormat"
                            >
                                Укажите корректный номер телефона
                            </div>
                        </div>
                    </el-form-item>
                </div>
                <div class="label-block">
                    Телефон нужен для оперативного решения вопросов по
                    бронированию
                </div>
            </div>
            <div class="form-line checkbox-line">
                <el-checkbox v-model="checked"
                    >Я хочу узнавать о специальных предложения по email или
                    SMS</el-checkbox
                >
            </div>
            <div class="form-line checkbox-line">
                <el-checkbox 
                    v-model="checkedRule" 
                    @change="$v.checkedRule.$touch()"
                    @blur="$v.checkedRule.$touch()"
                    :class="{
                                'el-form-error': $v.checkedRule.$error,
                            }"
                    >
                    Осуществляя бронирование вы принимаете
                    <a @click="handleInfoClick(2)" href="javascript:void(0)">правила онлайн-бронирования</a>
                    и соглашаетесь с
                    <a @click="handleInfoClick(3)" href="javascript:void(0)">обработкой персональных данных</a>
                </el-checkbox>
                <div v-if="$v.checkedRule.$error">
                    <div class="el-form-item__error long__error"  >
                        Необходимо согласие с условиями
                    </div>
                </div>
            </div>
            
            
                            
           
            
        </div>

        <div class="form-fields-group">
            <h2>Введите данные гостей</h2>

            <div
                class="form-field-group"
                v-for="(item, idx) in GUEST_PARAMS"
                :key="idx"
            >
                <h3 class="head-title form-title">Номер {{ idx + 1 }}</h3>
                <div v-for="(i, idx2) in GUESTS[idx]" :key="idx2">
                    <div class="form-title">
                        <div>
                            <div v-if="i <= item.adults.quantity">
                                Гость {{ i }}: Взрослый на
                                <span v-if="!item.adults.isDop[idx2]">
                                    основном
                                </span>
                                <span v-else>дополнительном</span> месте
                                <div>
                                    Путевка:
                                    {{
                                        getTariffTitle(
                                            item.adults.tariffIds[idx2].id
                                        )
                                    }}
                                </div>
                            </div>
                            <div v-else>
                                Ребенок на
                                <span
                                    v-if="
                                        !item.childs.isDop[
                                            idx2 - item.adults.quantity
                                        ]
                                    "
                                >
                                    основном
                                </span>
                                <span v-else>дополнительном</span> месте
                            </div>
                        </div>
                    </div>
                    <FioLine
                        :pSecondName="contactForm.payerSecondName"
                        :pName="contactForm.payerName"
                        :pThirdName="contactForm.payerThirdName"
                        :isAdult="i <= item.adults.quantity"
                        :roomIdx="idx"
                        :guestIdx="idx2"
                        :secondName="contactForm.fio[idx][idx2].secondName"
                        :name="contactForm.fio[idx][idx2].name"
                        :thirdName="contactForm.fio[idx][idx2].thirdName"
                        @input="fioInput($event)"
                    />
                </div>
                <RoomLevelUp
                    :roomId="GUEST_PARAMS[idx].roomId"
                    :roomNumber="idx"
                    @syncRoom="syncRoomsWithOrder()"
                    :handlerRoomInfo="handlerRoomInfo"
                    :scrollToTop="scrollToTop"
                    :backendRooms="backendRooms"
                />
            </div>

            <div class="form-field-group">
                <div class="form-title">
                    <div>Комментарии к заказу</div>
                </div>

                <div class="form-line">
                    <el-form-item prop="comment" class="textarea-item">
                        <el-input
                            type="textarea"
                            :autosize="{ minRows: 3, maxRows: 8 }"
                            placeholder="Если у вас есть пожелания, укажите их здесь"
                            v-model="contactForm.comment"
                        >
                        </el-input>
                    </el-form-item>
                </div>

                <!-- <div class="form-line promocode-line">
                    <el-form-item prop="promocode">
                        <el-input
                            placeholder="Введите промокод"
                            v-model="contactForm.promocode"
                        ></el-input>
                    </el-form-item>
                    <div class="label-block">
                        Есть промокод? Укажите его в этом поле
                    </div>
                </div> -->
            </div>
        </div>
    </el-form>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import FioLine from "./FioLine.vue";
import RoomLevelUp from "./RoomLevelUp.vue";
import axios from "axios";
import { required, email, minLength, sameAs} from "vuelidate/lib/validators";

export default {
    // mixins:[validationMixin],
    components: {
        FioLine,
        RoomLevelUp,
    },
    props: {
        handlerRoomInfo: Function,
        scrollToTop: Function,
        backendRooms: Array,
        handleInfoClick: Function

    },
    data() {
        return {
            contactForm: {
                domains: [
                    {
                        key: 1,
                        value: "",
                    },
                ],
                customerName: "",
                customerSecondName: "",
                customerThirdName: "",
                email: "",
                phone: "",
                phone2: "",
                fio: [
                    [
                        {
                            secondName: "",
                            name: "",
                            thirdName: "",
                        },
                        {
                            secondName: "",
                            name: "",
                            thirdName: "",
                        },
                        {
                            secondName: "",
                            name: "",
                            thirdName: "",
                        },
                        {
                            secondName: "",
                            name: "",
                            thirdName: "",
                        },
                        {
                            secondName: "",
                            name: "",
                            thirdName: "",
                        },
                    ],
                    [
                        {
                            secondName: "",
                            name: "",
                            thirdName: "",
                        },
                        {
                            secondName: "",
                            name: "",
                            thirdName: "",
                        },
                        {
                            secondName: "",
                            name: "",
                            thirdName: "",
                        },
                        {
                            secondName: "",
                            name: "",
                            thirdName: "",
                        },
                        {
                            secondName: "",
                            name: "",
                            thirdName: "",
                        },
                    ],
                    [
                        {
                            secondName: "",
                            name: "",
                            thirdName: "",
                        },
                        {
                            secondName: "",
                            name: "",
                            thirdName: "",
                        },
                        {
                            secondName: "",
                            name: "",
                            thirdName: "",
                        },
                        {
                            secondName: "",
                            name: "",
                            thirdName: "",
                        },
                        {
                            secondName: "",
                            name: "",
                            thirdName: "",
                        },
                    ],
                ],
                payerSecondName: "",
                payerName: "",
                payerThirdName: "",
                promocode: "",
                comment: "",
            },
            checked: true,
            checkedFio: false,
            step: this.$store.state.step,
            checkedRule: false,
        };
    },
    validations: {
        contactForm: {
            email: {
                required,
                email,
            },
            phone: {
                required,
                validFormat: (val) =>
                    /^\+?[1-9] \(?\d{3}\)? \d{3}-?\d{2}-?\d{2}$/.test(val),
            },
            payerName: {
                required,
                minLength: minLength(2),
            },
            payerSecondName: {
                required,
                minLength: minLength(2),
            },
            
        },
        checkedRule: {
                required, 
                sameAs: sameAs(() => true)
            }
        
    },
    created: async function () {
        await this.syncRoomsWithOrder();
        //await this.addAllRoomsToOrder();
    },
    computed: {
        ...mapGetters([
            "GUEST_PARAMS",
            "START_DATE",
            "END_DATE",
            "DATE_OUT",
            "DAYS",
            "ADD_ROOM_URL",
            "SESSION_DATA",
            "GUESTS",
            "TARIFFS",
            "SEARCH_DATA",
            "END_DATE_PREV",
            "SAVE_CART_URL",
            "ADDED_ROOMS",
            "REMOVE_URL",
        ]),
    },

    methods: {
        ...mapActions(["SET_ADDED_ROOMS"]),

        resetForm(formName) {
            this.$refs[formName].resetFields();
        },
        removeDomain(item) {
            var index = this.contactForm.domains.indexOf(item);
            if (index !== -1) {
                this.contactForm.domains.splice(index, 1);
            }
        },
        addDomain() {
            this.contactForm.domains.push({
                key: Date.now(),
                value: "",
            });
        },
        getphoneValue(input) {
            // Return stripped input value — just numbers
            return input.replace(/\D/g, "");
        },
        inputPhone() {
            let phoneValue = this.getphoneValue(this.contactForm.phone);
            let formattedPhoneValue = "";
            if (!phoneValue) {
                this.contactForm.phone = "";
            }
            //^\+?[1-9][-\(]?\d{3}\)?-?\d{3}-?\d{2}-?\d{2}$
            //Для российских и части казахских номеров
            if (["7", "8", "9"].indexOf(phoneValue[0]) > -1) {
                let firstSymbols = phoneValue[0] == "8" ? "8" : "+7";
                formattedPhoneValue = firstSymbols + " ";
                if (phoneValue.length == 1 && phoneValue[0] == "9") {
                    formattedPhoneValue = "+7 (" + phoneValue[0];
                }
                if (phoneValue.length > 1) {
                    formattedPhoneValue += "(" + phoneValue.substring(1, 4);
                }
                if (phoneValue.length >= 5) {
                    formattedPhoneValue += ") " + phoneValue.substring(4, 7);
                }
                if (phoneValue.length >= 8) {
                    formattedPhoneValue += "-" + phoneValue.substring(7, 9);
                }
                if (phoneValue.length >= 10) {
                    formattedPhoneValue += "-" + phoneValue.substring(9, 11);
                }
            } else {
                //все другие номера
                formattedPhoneValue = "+" + phoneValue.substring(0, 16);
            }

            this.contactForm.phone = formattedPhoneValue;
        },
        clearPhone() {
            this.contactForm.phone = "";
        },
        getRoomPrice(idx) {
            let price = 0;
            const item = this.GUEST_PARAMS[idx];
            // const adultPrices = item.adults.prices;
            // const childPrices = item.childs.prices;
            for (
                let innerIdx = 0;
                innerIdx < item.adults.tariffIds.length;
                innerIdx++
            ) {
                price += item.adults.tariffIds[innerIdx].price;
            }
            for (
                let innerIdx = 0;
                innerIdx < item.childs.tariffIds.length;
                innerIdx++
            ) {
                price += item.childs.tariffIds[innerIdx].price;
            }
            return price;
        },
        getRoomTariff(idx, roomIdx, guestIdx, tarriffId) {
            const guest = this.SEARCH_DATA[idx].availableRoom[roomIdx].guests[guestIdx];
            for (let index = 0; index < guest.tariffs.length; index++) {
                if (guest.tariffs[index].tariffId == tarriffId) {
                    return guest.tariffs[index]
                }
            }
        },

        getGuests(idx, roomId) {
            let guests = [];
            let rooms = this.SEARCH_DATA[idx].availableRoom;
            let tariffId = null;
            let adultsQuantity = this.GUEST_PARAMS[idx].adults.quantity;
            for (let index = 0; index < rooms.length; index++) {
                const room = rooms[index];
                if (room.id == roomId) {
                    for (let idx2 = 0; idx2 < room.guests.length; idx2++) {
                        let guest = room.guests[idx2];
                        if (guest.age == -1) {
                            tariffId =
                                this.GUEST_PARAMS[idx].adults.tariffIds[idx2]
                                    .id;
                        } else {
                            tariffId =
                                this.GUEST_PARAMS[idx].childs.tariffIds[
                                    idx2 - adultsQuantity
                                ].id;
                        }
                        let guestNew = {
                            age: guest.age,
                            idx: guest.idx2,
                            isDop: guest.isDop,
                            FIO: "",
                            SEX: "",
                            selectTariff: this.getRoomTariff(
                                idx,
                                index,
                                idx2,
                                tariffId,
                            ),
                        };

                        guests.push(guestNew);
                    }
                }
            }
            return guests;
        },
        async addRoomToOrder(idx) {
            let roomId = this.GUEST_PARAMS[idx].roomId;
            let addedRoom = null;
            let data = [
                {
                    requestId: null,
                    clusterId: null,
                    roomId: roomId,
                    roomDescription: this.GUEST_PARAMS[idx].descZdrav,
                    roomSum: this.getRoomPrice(idx),
                    dateIn: this.START_DATE,
                    dateOut: this.END_DATE_PREV,
                    dateOutReal: this.END_DATE,
                    days: this.DAYS,
                    guest: this.getGuests(idx, roomId),
                },
            ];
            await axios
                .post(this.ADD_ROOM_URL, data, {
                    headers: { Authorization: `Bearer ${this.SESSION_DATA}` },
                })
                .then((res) => {
                    addedRoom = res.data.data;
                })
                .catch((err) => {
                    console.log("ERROR_MESSAGE", err.response.data.message);
                    if (err.request.status == 400) {
                        this.$emit('returnToStart')
                        console.log("!!!!_400");
                    }
                    if (err.request.status == 401) {
                        console.log("!!!!_401");
                        this.$emit("setSession");
                        setTimeout(this.addRoomToOrder, 1000, idx);
                    }
                });
            return addedRoom;
        },
        async syncRoomsWithOrder() {
            if (this.ADDED_ROOMS == null || this.ADDED_ROOMS[0].room == null) {
                await this.addAllRoomsToOrder();
            } else {
                this.$emit("removeAllRooms");
                await this.SET_ADDED_ROOMS(null);
                await this.addAllRoomsToOrder();
            }
            this.$emit("addingRoomsFinished");
        },
        async addAllRoomsToOrder() {
            let addedRooms = [];
            for (let idx = 0; idx < this.GUEST_PARAMS.length; idx++) {
                let room = await this.addRoomToOrder(idx);
                addedRooms.push({
                    room: room,
                    guestParamsId: this.GUEST_PARAMS[idx].id,
                });
            }
            this.SET_ADDED_ROOMS(addedRooms);
        },
        getTariffTitle(id) {
            for (let idx = 0; idx < this.TARIFFS.length; idx++) {
                const tariffs = this.TARIFFS[idx].tariffs;
                for (let innerIdx = 0; innerIdx < tariffs.length; innerIdx++) {
                    const tariff = tariffs[innerIdx];
                    if (tariff.tariffId == id) {
                        return tariff.title;
                    }
                }
            }
        },
        async saveToCart() {
            let rooms = [];
            for (let idx = 0; idx < this.ADDED_ROOMS.length; idx++) {
                let room = this.ADDED_ROOMS[idx].room;
                for (let idx2 = 0; idx2 < room.guest.length; idx2++) {
                    room.guest[
                        idx2
                    ].FIO = `${this.contactForm.fio[idx][idx2].secondName} ${this.contactForm.fio[idx][idx2].name} ${this.contactForm.fio[idx][idx2].thirdName}`;
                }
                rooms.push(room);
            }
            const payerFullName = `${this.contactForm.payerSecondName} ${this.contactForm.payerName} ${this.contactForm.payerThirdName}`;
            let data = [
                {
                    rooms: rooms,
                    customerFIO: payerFullName,
                    phone: this.contactForm.phone,
                    email: this.contactForm.email,
                },
            ];
            await axios
                .post(this.SAVE_CART_URL, data, {
                    headers: { Authorization: `Bearer ${this.SESSION_DATA}` },
                })
                .catch((err) => {
                    console.log("ERROR_MESSAGE", err.response.data.message);
                    if (err.request.status == 400) {
                        this.$emit('returnToStart')
                    }
                    if (err.request.status == 401) {
                        this.$emit('setSession')
                        this.saveToCart();
                    }
                });
        },
        fioInput(evnt) {
            this.contactForm.fio[evnt.idx][evnt.idx2].secondName =
                evnt.secondName;
            this.contactForm.fio[evnt.idx][evnt.idx2].name = evnt.name;
            this.contactForm.fio[evnt.idx][evnt.idx2].thirdName =
                evnt.thirdName;
        },
        scrollToElement(id) {
            let element = document.getElementById(id);
            element.scrollIntoView({ behavior: "smooth" });
        },
        
    },
};
</script>
