<template>
    <div class="step-2">
        <header>
            <div class="top-header">
                <div class="header-back" @click="setStep(1)" v-if="roomIdx==0">
                    <i class="el-icon-arrow-left"></i>
                    <span>Назад к датам</span>
                </div>
                <div class="header-back" @click="decrementRoomIdx()" v-else>
                    <i class="el-icon-arrow-left"></i>
                    <span>Назад</span>
                </div>

                <div class="header-dates" @click="setStep(1)">
                    <i class="las la-calendar-week"></i>
                    <span
                        >{{ formatDate(startDate) }} -
                        {{ formatDate(endDate) }} ({{ nightsInfo }})</span
                    >
                </div>

                <div class="header-room" @click="setStep(1)">
                    <i class="las la-user"></i>
                    <span>{{ bookingInfo }}</span>
                </div>
            </div>

            <el-progress :percentage="50" :format="format()"></el-progress>
        </header>

        <div class="header-featured-image">
            <img :src="bannerURL" alt="featured image" />
        </div>
        <div class="preloader load-data-error" v-if="!isDataReady">
            <div class="error-view">
                <p class="error-info">Ошибка получения данных от сервера</p>
                <p>
                    Пожалуйста, попробуйте снова или обратитесь к менеджеру
                    позвонив по телефону
                    <a href="tel:8-800-707-5182">8 800 707 5182</a>.
                </p>
                <el-button
                    class="error-button"
                    type="primary"
                    @click="setStep(1)"
                >
                    Попробовать заново
                </el-button>
            </div>
        </div>

        <div
            class="message-view"
            v-if="searchData[0].availableRoom.length == 0 && isDataReady"
        >
            <p class="error-info">
                Не найдено свободных номеров по вашим критериям
            </p>
            <p>
                Пожалуйста, попробуйте снова, выбрав другие условия или
                обратитесь к менеджеру позвонив по телефону
                <a href="tel:8-800-707-5182">8 800 707 5182</a>.
            </p>
            <el-button class="error-button" type="primary" @click="setStep(1)">
                Попробовать заново
            </el-button>
        </div>

        <template v-else>
            <div
                v-if="searchData.length == guestParams.length && isDataReady"
                :key="roomIdx"
            >
                <div class="step-heading">
                    <h3>
                        Выберите номер {{ roomIdx + 1 }} в {{ division_name }}
                    </h3>
                    <div class="room-view">
                        <div
                            v-bind:class="[{ active: isGridView }, gridClass]"
                            @click="isGridView = true"
                        >
                            <i class="el-icon-menu"></i>
                        </div>
                        <div
                            :class="[{ active: !isGridView }, listClass]"
                            @click="isGridView = false"
                        >
                            <i class="las la-bars"></i>
                        </div>
                    </div>
                </div>
               
                <div class="grid-view" v-if="isGridView == true">
                    <div
                        class="room"
                        v-for="room in roomsFiltredByIdx(roomIdx)"
                        :key="room.id"
                    >
                        <div
                            class="card"
                            :class="{
                                'active-card':
                                    guestParams[roomIdx].roomId == room.id,
                            }"
                        >
                            <div class="picture">
                                <el-carousel
                                    :autoplay="false"
                                    height="230px"
                                    v-if="thumbnails2x[room.id]"
                                >
                                    <el-carousel-item
                                        v-for="(
                                            image, imageIndex
                                        ) in thumbnails2x[room.id]"
                                        :key="imageIndex"
                                        class="images-wrapper"
                                    >
                                        <div
                                            class="image"
                                            :style="{
                                                backgroundImage:
                                                    'url(' + image + ')',
                                            }"
                                            @click="
                                                index = imageIndex;
                                                handlerCarouselClick(
                                                    room.id,
                                                    imageIndex
                                                );
                                            "
                                        ></div>
                                    </el-carousel-item>
                                </el-carousel>
                                <el-carousel
                                    :autoplay="false"
                                    height="230px"
                                    v-else
                                >
                                    <el-carousel-item
                                        v-for="(image, imageIndex) in [
                                            'img/placeholder.png',
                                        ]"
                                        :key="imageIndex"
                                        class="images-wrapper"
                                    >
                                        <div
                                            class="image"
                                            :style="{
                                                backgroundImage:
                                                    'url(' + image + ')',
                                            }"
                                            @click="
                                                index = imageIndex;
                                                handlerCarouselClick(
                                                    room.id,
                                                    imageIndex
                                                );
                                            "
                                        ></div>
                                    </el-carousel-item>
                                </el-carousel>

                                <div class="features">
                                    <div
                                        v-for="(
                                            item, index
                                        ) in room.main_equipment"
                                        :key="index"
                                    >
                                        <i class="las" :class="item.icon"></i>
                                        <!-- {{item}} -->
                                    </div>
                                </div>
                                <div class="stickers">
                                    <div
                                        class="sticker"
                                        v-if="room.sales_leader"
                                    >
                                        Лидер продаж
                                    </div>
                                </div>
                            </div>
                            <div
                                class="description"
                                @click="handlerRoomInfo(room.id, type='available', upsell=false, roomIdx)"
                            >
                                <div class="title">
                                    {{ room.title }}
                                </div>
                                <div class="column">
                                    <div class="properties">
                                        <div>
                                            <i
                                                class="las la-ruler-combined"
                                            ></i>
                                            <span>{{ room.area }} м²</span>
                                        </div>
                                        <div>
                                            <i class="las la-user-friends"></i>
                                            <span
                                                >до
                                                {{ room.seats_number }}
                                                мест</span
                                            >
                                        </div>
                                    </div>
                                </div>

                                <div class="column">
                                    <div class="properties">
                                        <div></div>
                                        <div class="last-room">
                                            <span v-if="room.isLast"
                                                >Остался 1 номер</span
                                            >
                                        </div>
                                        <div>
                                            <div
                                                class="link"
                                                @click="
                                                    handlerRoomInfo(room.id, type='available', upsell=false, roomIdx)
                                                "
                                            >
                                                Описание номера
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="footer">
                                    <div class="column">
                                        <div class="price">
                                            <div class="price-numbers">
                                                <!-- <div class="old-price">
                                                    62 000 ₽
                                                </div> -->
                                                <div class="actual-price">
                                                    от
                                                    <b>{{ room.minPrice.toLocaleString('ru') }}</b> ₽
                                                </div>
                                            </div>
                                            <!-- <div class="sale">-5%</div> -->
                                        </div>
                                    </div>
                                    <div class="column">
                                        <el-button
                                            class="choose"
                                            type="primary"
                                            @click.stop="
                                                choiceRoom(room, roomIdx)
                                            "
                                            >Выбрать</el-button
                                        >
                                        <el-button
                                            class="choosen"
                                            type="primary"
                                            @click.stop="
                                                choiceRoom(room, roomIdx)
                                            "
                                        >
                                            <i class="las la-check"></i>
                                            Далее
                                        </el-button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="list-view" v-if="isGridView == false">
                    <div
                        class="room"
                        v-for="room in roomsFiltredByIdx(roomIdx)"
                        :key="room.id"
                    >
                        <div
                            class="item"
                            :class="{
                                'active-card':
                                    guestParams[roomIdx].roomId == room.id,
                            }"
                        >
                            <div
                                class="picture"
                                v-if="
                                    thumbnails2x[room.id] &&
                                    thumbnails2x[room.id].length > 0
                                "
                                @click="handlerRoomInfo(room.id, type='available', upsell=true, roomIdx)"
                            >
                                <img :src="thumbnails2x[room.id][0]" alt="" />
                                <div class="features">
                                    <div
                                        v-for="(
                                            item, index
                                        ) in room.main_equipment"
                                        :key="index"
                                    >
                                        <i class="las" :class="item.icon"></i>
                                    </div>
                                </div>
                            </div>
                            <div class="picture" v-else>
                                <img src="img/placeholder.png" alt="" />
                                <div class="features">
                                    <div
                                        v-for="(
                                            item, index
                                        ) in room.main_equipment"
                                        :key="index"
                                    >
                                        <i class="las" :class="item.icon"></i>
                                    </div>
                                </div>
                            </div>
                            <div
                                class="description"
                                @click="handlerRoomInfo(room.id, type='available', upsell=true, roomIdx)"
                            >
                                <div class="main column">
                                    <div class="title">
                                        {{ room.title }}
                                    </div>
                                    <div class="properties">
                                        <div class="last-room">
                                            <span v-if="room.isLast"
                                                >Остался 1 номер</span
                                            >
                                        </div>
                                        <div>
                                            <div>
                                                <i
                                                    class="
                                                        las
                                                        la-ruler-combined
                                                    "
                                                ></i>
                                                <span>{{ room.area }} м²</span>
                                            </div>
                                            <div>
                                                <i
                                                    class="las la-user-friends"
                                                ></i>
                                                <span
                                                    >до
                                                    {{ room.seats_number }}
                                                    мест</span
                                                >
                                            </div>
                                        </div>

                                        <div>
                                            <div
                                                class="link"
                                                @click="
                                                    handlerRoomInfo(room.id, type='available', upsell=true, roomIdx)
                                                "
                                            >
                                                Описание номера
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="footer column">
                                    <div class="price">
                                        <div class="price-numbers">
                                            <div class="actual-price">
                                                от <b>{{ room.minPrice.toLocaleString('ru') }}</b> ₽
                                            </div>
                                        </div>
                                        <!-- <div class="sale">-5%</div> -->
                                    </div>

                                    <el-button
                                        class="choose"
                                        type="primary"
                                        @click.stop="choiceRoom(room, roomIdx)"
                                        >Выбрать</el-button
                                    >
                                    <el-button
                                        class="choosen"
                                        type="primary"
                                        @click.stop="choiceRoom(room, roomIdx)"
                                    >
                                        <i class="las la-check"></i>
                                        Далее
                                    </el-button>
                                </div>
                            </div>
							<div class="stickers">
								<div
									class="sticker"
									v-if="room.sales_leader"
								>
									Лидер продаж
								</div>
							</div>
                        </div>
                    </div>
                </div>
            </div>
        </template>
		<div class="alternative-dates list-view" v-if="roomsFiltredByIdx(roomIdx, 'other') && roomIdx==0">
			<h3>Номера доступные на ближайшие даты</h3>
			<p>Посмотрите другие варианты проживания с альтернативными датами заезда</p>
			<div
                class="single-room" 
                v-for="(room, count) in roomsFiltredByIdx(roomIdx, 'other')"
                :key="count"
            >
				<div class="room">
					<div class="item">
						<div
							class="picture"
							v-if="
								thumbnails2x[room.id] &&
								thumbnails2x[room.id].length >
									0
							"
                            @click="handlerRoomInfo(room.id, type='other', upsell=true, roomIdx)"
						>
						<div class="stickers">
							<div class="sticker" v-if="room.sales_leader">
								Лидер продаж
							</div>
						</div>
							<img
								:src="
									thumbnails2x[room.id][0]
								"
								alt=""
							/>
							<div class="features">
								<div
									v-for="(item, index) in room.main_equipment"
									:key="index"
								>
									<i class="las" :class="item.icon"></i>
								</div>
							</div>
						</div>
						<div class="picture" v-else>
							<img src="img/placeholder.png" alt="" />
							<div class="features">
								<div
									v-for="(item, index) in room.main_equipment"
									:key="index"
								>
									<i class="las" :class="item.icon"></i>
								</div>
							</div>
						</div>
						<div
							class="description"
							@click="handlerRoomInfo(room.id, type='other', upsell=true, roomIdx)"
						>
							<div class="main column">
								<div class="title">
									{{ room.title }}
								</div>

								<div class="price">
									от <b>{{ room.minPrice.toLocaleString('ru') }}</b> ₽ в сутки
								</div>

								<div class="properties">
									<div class="last-room">
									</div>
									<div>
										<div>
											<i class="las la-ruler-combined"></i>
											<span
												>{{
													room.area
												}}
												м²</span
											>
										</div>
										<div>
											<i class="las la-user-friends"></i>
											<span
												>до
												{{
													room.seats_number
												}}
												мест</span
											>
										</div>
									</div>

									<div>
										<div
											class="link"
											@click="
												handlerRoomInfo(room.id, type='other', upsell=false, roomIdx)
											"
										>
											Описание номера
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="desktop-table">
							<el-table
								:data="room.otherDates"
								style="width: 100%"
								:highlight-current-row="true"
							>
								<el-table-column label="Дата заезда" prop="date_in"></el-table-column>
								<el-table-column label="Дата выезда" prop="date_out"></el-table-column>
								<el-table-column label="Ночей" align="center" width="70px" prop="days"></el-table-column>
								<el-table-column>
                                    <template slot-scope="scope">
                                        <div class="link" @click="handleOtherDatesClick(room, roomIdx, count, scope.$index)">
                                            Выбрать дату
                                        </div>
                                    </template>
								</el-table-column>
							</el-table>
						</div>
						<div class="mobile-table">
							<el-table
								:data="room.otherDates"
								style="width: 100%"
								:highlight-current-row="true"
							>
								<el-table-column label="Заезд" prop="date_in"></el-table-column>
								<el-table-column label="Ночей" align="center" width="70px" prop="days"></el-table-column>
								<el-table-column width="100px">
									<template slot-scope="scope">
										<div class="link" @click="handleOtherDatesClick(room, roomIdx, count, scope.$index)">
											Выбрать
										</div>
									</template>
								</el-table-column>
							</el-table>
						</div>
						
					</div>
				</div>

			</div>
		</div>

        <div
            class="footer-info"
            v-if="isDataReady && searchData[0].availableRoom.length > 0"
        >
            Не нашли интересующий вас вариант? Позвоните нам по телефону
            <a href="tel:8-800-707-5182">8 800 707 5182</a>, мы поможем.
        </div>
    </div>
</template>

<script>
// import RoomMoreInfoDrawer from "./RoomMoreInfoDrawer.vue";
import { mapActions, mapState } from "vuex";
import { formatProgressBar, formatDateToUTC } from "../api.js";
// import axios from "axios";
import EventBus from "../event-bus.js";

export default {
    // components: {
    //     RoomMoreInfoDrawer,
    // },
    props: {
        fillPhotos: Function,
        fillRooms: Function,
        handlerRoomInfo: Function,
        choiceRoom: Function,
        photos: Object,
        thumbnails: Object,
        thumbnails2x: Object,
        rooms: Array,
        otherRooms: Array,
        roomIdx: Number,
        searchComplite: Boolean,
        backendRooms: Array,
        backendTariffs: Array,
        nightInfo: Function,
		scrollToTop: Function,
		getWindowHeight: Function,
        decrementRoomIdx: Function,
        handlerCarouselClick: Function
    },
    data() {
        return {
            isOpenRoomMoreInfo: false,
            isGridView: true,
            listClass: "list",
            gridClass: "grid",
            activeCardClass: "active-card",
            roomInfo: {},
            currentRoomId: null,
            index: null,
            isDataReady: true,
            tableData: [
                {
                    id: 100,
                    name: "",
                    descr: 1,
                },
                {
                    id: 150,
                    name: "",
                    descr: 2,
                },
                {
                    id: 120,
                    name: "",
                    descr: 2,
                },
            ],
        };
    },
    mounted: async function () {
        this.fillRooms();
        this.fillRooms('other');
        this.fillPrices();
        // setTimeout(this.scrollToActiveCard, 500)
        setTimeout(this.scrollToActiveCard, 0)
        // this.fillPhotos();
        // this.fillPhotos('other')
    },
    // beforeUpdate: function () {
    //     this.getDrawerMaxHeight()
    // },
    computed: {
        ...mapState([
            "bookingInfo",
            "nightsInfo",
            "searchData",
            "startDate",
            "endDate",
            "guestParams",
            // "backendRooms",
            "backApiUrl",
            "settings",
        ]),
        roomsFiltred() {
            return this.roomsFiltredByIdx(this.roomIdx);
        },
        bannerURL() {
            return this.$store.state.settings[0].banner;
        },
        division_name() {
            let division = this.$store.state.division;
            if (division === "e66f7d7a-a8fd-4aea-a5b5-b6ace867b7c2") {
                return 'санатории "Катунь"';
            }
            if (division === "dcb6fbcb-7009-47ff-baad-c0027817ddbc") {
                return 'санатории "Сибирь"';
            }
            if (division === "70967e77-a573-4294-a69f-17648bd1b003") {
                return 'санатории "Белокуриха"';
            }
            return "";
        },
    },
    methods: {
        ...mapActions([
            "SET_STEP",
            "SET_ROOMS",
            "SET_BACKEND_ROOMS",
            "SET_BACKEND_TARIFFS",
            "SET_GUEST_PARAMS",
            "SET_PRICES",
            "SET_DAYS",
            "SET_START_DATE",
            "SET_END_DATE",
            "SET_END_DATE_PREV",
            "SET_NIGHTS_INFO"
        ]),
        setStep(stepNumber) {
            this.SET_STEP(stepNumber);
        },
        formatDate(inputDate) {
            const date = new Date(inputDate);
            const month = date.getMonth();
            const day = date.getDate();

            if (month === 0) {
                return `${day} января`;
            } else if (month === 1) {
                return `${day} февраля`;
            } else if (month === 2) {
                return `${day} марта`;
            } else if (month === 3) {
                return `${day} апреля`;
            } else if (month === 4) {
                return `${day} мая`;
            } else if (month === 5) {
                return `${day} июня`;
            } else if (month === 6) {
                return `${day} июля`;
            } else if (month === 7) {
                return `${day} августа`;
            } else if (month === 8) {
                return `${day} сентября`;
            } else if (month === 9) {
                return `${day} октября`;
            } else if (month === 10) {
                return `${day} ноября`;
            } else {
                return `${day} декабря`;
            }
        },
        format() {
            return formatProgressBar;
        },

        minTariffPrice(room) {
            let tariffs = room.guests[0].tariffs;
            let minTariff = tariffs[0].fullPrice;
            for (let idx = 0; idx < tariffs.length; idx++) {
                if (tariffs[idx].fullPrice < minTariff) {
                    minTariff = tariffs[idx].fullPrice;
                }
            }
            return minTariff;
        },

        consoleLog(msg, variable) {
            console.log(msg, variable);
        },

        fillPrices() {
            let pricesTable = [];
            for (let idx = 0; idx < this.searchData.length; idx++) {
                let availableRooms = this.searchData[idx].availableRoom;
                for (let idx2 = 0; idx2 < availableRooms.length; idx2++) {
                    let room = availableRooms[idx2];
                    for (let idx3 = 0; idx3 < room.guests.length; idx3++) {
                        let guest = room.guests[idx3];
                        let tariffs = guest.tariffs;
                        for (let idx4 = 0; idx4 < tariffs.length; idx4++) {
                            let tariff = tariffs[idx4];
                            let tableItem = {
                                roomNumber: idx,
                                roomId: room.id,
                                age: guest.age,
                                tariffId: tariff.tariffId,
                                price: tariff.fullPrice,
                                isRoom: tariff.isRoom,
                            };
                            pricesTable.push(tableItem);
                        }
                    }
                }
            }
            this.SET_PRICES(pricesTable);
        },
        roomsFiltredByIdx(idx, type='available') {
            let filtredRooms = []
            if (type == 'available') {
                filtredRooms = this.rooms.filter((item) => {
                    return item.idx == idx;
                });
            }
            if (type == 'other') {
                filtredRooms = this.otherRooms.filter((item) => {
                    return item.idx == idx;
                });
            }
            return filtredRooms;
        },
        showMessage(msg, msgType) {
            this.$message({
                message: msg,
                type: msgType,
                duration: 4000,
            });
        },
        // handlerCarouselClick(roomId, imageIndex) {
        //     this.currentRoomId = roomId;
        //     const SITE_URL = this.$store.state.parentSiteUrl;
        //     parent.postMessage(
        //         { roomPhotos: this.photos[roomId], imageIndex: imageIndex },
        //         SITE_URL
        //     );
        // },
        handleOtherDatesClick(room, roomIdx, roomCount, rowIndex) {
            let date_in = this.otherRooms[roomCount].otherDates[rowIndex].date_in
            let date_out = this.otherRooms[roomCount].otherDates[rowIndex].date_out
            const startDate = new Date(date_in)
            const endDate = new Date(date_out)
            const endDatePrev = new Date(date_out)
            endDatePrev.setDate(endDatePrev.getDate() - 1)
            const dateDiff = Math.abs(Date.parse(endDate) - Date.parse(startDate))
            const days = Math.ceil(dateDiff / (1000 * 3600 * 24));
            this.SET_DAYS(days);
            this.SET_START_DATE(formatDateToUTC(startDate));
            this.SET_END_DATE(formatDateToUTC(endDate));
            this.SET_END_DATE_PREV(formatDateToUTC(endDatePrev))
            this.SET_NIGHTS_INFO(this.nightInfo());
			this.getWindowHeight()
            this.SET_STEP(5)
            EventBus.$emit("searchRooms")
            this.choiceRoom(room, roomIdx, 'other')
        },
		scrollToActiveCard() {
			let top = 0;
			let element = document.querySelector(".active-card");
			if (element) {
				top = element.offsetTop - 20;
			}
			this.scrollToTop(top)
		}
},
};
</script>
