<template>
  <el-input-number
    v-model="num"
    @change="handleChange"
    :min="2"
    :max="100"
  ></el-input-number>
</template>
<script>
import { mapActions } from 'vuex';
import { formatDateToUTC } from '../api'
    export default {
        data() {
            return {
                num: this.$store.state.days,
                startDate:  this.$store.state.startDate
            };
        },
        props: {
            nightInfo: Function
        },
        methods: {
            ...mapActions([
                "SET_DAYS", 
                "SET_END_DATE", 
                "SET_END_DATE_PREV",
                "SET_NIGHTS_INFO"]),
            setEndAndPrevDate(days) {
                const startDateInMs = Date.parse(this.startDate)
                const daysInMs = days * 24 * 3600 * 1000
                const endDateinMs = startDateInMs + daysInMs
                const endDate = new Date(endDateinMs)
                const endDatePrev = new Date(Date.parse(endDate) - (1000 * 3600 * 24))
                this.SET_END_DATE(formatDateToUTC(endDate))
                this.SET_END_DATE_PREV(formatDateToUTC(endDatePrev))
            },
            handleChange(value) {
                this.SET_DAYS(value)
                this.setEndAndPrevDate(value)
                this.SET_NIGHTS_INFO(this.nightInfo())
            }
        }
    };
</script>
