<template>
  <el-select
  v-model="value"
  placeholder="Выберите санаторий"
  @change="selectChanged()"
  >
    <el-option
      v-for="item in options"
      :key="item.value"
      :label="item.label"
      :value="item.value">
    </el-option>
  </el-select>
</template>

<script>
  import { mapActions } from "vuex"
  export default {
    data() {
      return {
            options: [{
                value: '70967e77-a573-4294-a69f-17648bd1b003',
                label: 'Санаторий "Белокуриха"'
                }, {
                value: 'e66f7d7a-a8fd-4aea-a5b5-b6ace867b7c2',
                label: 'Санаторий "Катунь"'
                }, {
                value: 'dcb6fbcb-7009-47ff-baad-c0027817ddbc',
                label: 'Санаторий "Сибирь" '
            }],
            value: this.$store.state.division
        }
    },
    methods: {
      ...mapActions(["SET_DIVISION"]),
      selectChanged() {
        this.SET_DIVISION(this.value)
        this.$emit("isFill")
      }
    }
  }
</script>