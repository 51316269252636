<template>
    <div class="step-1">
        <h1>Онлайн-бронирование путевок</h1>
        <div class="content">
            <el-row>
                <el-col :xs="24" :sm="12">
                    <div class="grid-content">
                        <h3>Выберите даты заезда и выезда</h3>
                        <DatePicker
                            :key="days"
                            @datesChange="updateInputKey($event)"
                            @isFill="isDatesEmpty = false"
                            :nightInfo="nightInfo"
                            :class="{ 'el-form-error': isDatesEmpty }"
                        />
                        <div class="el-form-item__error" v-if="isDatesEmpty">
                            Пожалуйста, выберите даты заезда и выезда
                        </div>
                    </div>
                </el-col>
                <el-col v-if="days" class="right" :xs="24" :sm="12">
                    <div class="grid-content el-input-number-block">
                        <h3>Количество ночей</h3>
                        <InputNumber 
                        :key="inputKey" 
                        :nightInfo="nightInfo"/>
                    </div>
                </el-col>
            </el-row>
            <div v-for="(item, idx) in guestParams" :key="item.id">
                <el-row class="room-block" v-if="guestParams.length > 1">
                    <el-col :xs="12" :sm="12">
                        <div class="grid-content">
                            <h2>Номер {{ idx + 1 }}</h2>
                        </div>
                    </el-col>
                    <el-col :xs="12" :sm="12">
                        <div class="grid-content">
                            <div class="link" @click="removeRoom(idx)">
                                Удалить номер
                            </div>
                        </div>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :xs="24" :sm="12">
                        <div class="grid-content">
                            <h3>Взрослые (от 14 лет)</h3>
                            <AdultSelect
                                :key="adultsKey"
                                class="adult-select"
                                :selectedValue="item.adults.quantity"
                                @selectChanged="changeAdults(idx, $event)"
                            />
                        </div>
                    </el-col>
                    <el-col class="right" :xs="24" :sm="12">
                        <div class="grid-content">
                            <h3 class="child-title">
                                Дети (от 4 до 14 лет)
                                <el-popover
                                    placement="top-start"
                                    title=""
                                    width="auto"
                                    trigger="hover"
                                    content="Дети до 4 лет без предоставления спального места и лечения проживают бесплатно"
                                >
                                    <i
                                        slot="reference"
                                        class="las la-info-circle"
                                    ></i>
                                </el-popover>
                            </h3>
                            <div v-if="item.childs.ages">
                                <div
                                    class="select-child-block"
                                    v-for="(age, index) in item.childs.ages"
                                    :key="index"
                                >
                                    <ChildSelect
                                        class="child-selected"
                                        :selectedValue="age"
                                        @selectChanged="
                                            changeChildAge(idx, index, $event)
                                        "
                                    />
                                    <button
                                        class="child-remove"
                                        @click="removeChild(idx, index)"
                                    ></button>
                                </div>
                            </div>
                            <ChildSelect
                                class="child-add"
                                @selectChanged="addChildAge(idx, $event)"
                            />
                        </div>
                    </el-col>
                </el-row>
            </div>
            <el-row class="room-san-block">
                <el-col :xs="24" :sm="12">
                    <div class="grid-content">
                        <div class="link link-addroom" @click="addRoom">
                            Добавить еще 1 номер
                        </div>
                    </div>
                </el-col>
                <el-col class="right sanatorium-select-col" :xs="24" :sm="12">
                    <div class="grid-content">
                        <SanatoriumSelect
                            :key="sanatoriumKey"
                            @isFill="isSanatoriumEmpty = false"
                            :class="{ 'el-form-error': isSanatoriumEmpty }"
                        />
                        <div
                            class="el-form-item__error"
                            v-if="isSanatoriumEmpty"
                        >
                            Пожалуйста, выберите санаторий
                        </div>
                    </div>
                </el-col>
            </el-row>
            <el-button
                type="primary"
                @click="searchRooms"
                v-if="!isStartSearching"
                >Показать варианты</el-button
            >

            <el-button type="primary" :loading="true" v-else
                >Загрузка</el-button
            >

            <!-- <div class="preloader">
				<div class="lds-ring"><div></div><div></div><div></div><div></div></div>
			</div> -->
        </div>
    </div>
</template>

<script>
import DatePicker from "./DatePicker.vue";
import AdultSelect from "./AdultSelect.vue";
import ChildSelect from "./ChildSelect.vue";
import InputNumber from "./InputNumber.vue";
import SanatoriumSelect from "./SanatoriumSelect.vue";
import { mapActions, mapState } from "vuex";
import axios from "axios";
import { formatDateToUTC } from "../api";
import EventBus from "../event-bus.js";
export default {
    components: {
        DatePicker,
        AdultSelect,
        ChildSelect,
        InputNumber,
        SanatoriumSelect,
    },
    props: {
        nightInfo: Function,
        scrollToTop: Function,
        resetRoomIndex: Function,
        getSpkConfig: Function
    },
    data() {
        return {
            // guestParams: this.guestParams,
            inputKey: "",
            isDatesEmpty: false,
            isSanatoriumEmpty: false,
            isStartSearching: false,
            adultsKey: 1,
            sanatoriumKey: "",
        };
    },
    mounted: function () {
        EventBus.$on("searchRooms", () => {
            this.searchRooms();
        });
        this.resetRoomIndex()
    },
    beforeUpdate: async function () {
        this.getSettings();
    },
    computed: {
        ...mapState([
            "apiKey",
            "sessionUrl",
            "configUrl",
            "searchUrl",
            "session",
            "guests",
            "startDate",
            "division",
            "days",
            "guestParams",
            "searchData",
            "backApiUrl",
            "parentSiteUrl",
            "step",
        ]),
    },
    methods: {
        ...mapActions([
            "SET_GUEST_PARAMS",
            "SET_STEP",
            "SET_SESSION",
            "SET_CONFIG_DATA",
            "SET_SEARCH_DATA",
            "SET_GUESTS",
            "SET_BOOKING_INFO",
            "SET_ROOMS",
            "SET_BACKEND_ROOMS",
            "SET_BACKEND_TARIFFS",
            "SET_TARIFFS",
            "SET_START_DATE",
            "SET_DAYS",
            "SET_END_DATE",
            "SET_END_DATE_PREV",
            "SET_DIVISION",
            "SET_SETTINGS",
        ]),
        updateInputKey(value) {
            this.inputKey = value;
        },
        changeAdults(idx, value) {
            let localGuestParams = this.guestParams;
            localGuestParams[idx].adults.quantity = value;
            this.SET_GUEST_PARAMS(localGuestParams);
        },
        removeChild(idx, index) {
            let localGuestParams = this.guestParams;
            localGuestParams[idx].childs.ages.splice(index, 1);
            this.SET_GUEST_PARAMS(localGuestParams);
        },
        addChildAge(idx, value) {
            let localGuestParams = this.guestParams;
            localGuestParams[idx].childs.ages.push(value);
            this.SET_GUEST_PARAMS(localGuestParams);
        },
        changeChildAge(idx, index, value) {
            let localGuestParams = this.guestParams;
            localGuestParams[idx].childs.ages.splice(index, 1, value);
            this.SET_GUEST_PARAMS(localGuestParams);
        },
        removeRoom(idx) {
            let localGuestParams = this.guestParams;
            localGuestParams.splice(idx, 1);
            this.SET_GUEST_PARAMS(localGuestParams);
        },
        addRoom() {
            let localGuestParams = this.guestParams;
            const paramsId = localGuestParams.length + 1;
            localGuestParams.push({
                id: paramsId,
                adults: { quantity: 1, tariffIds: [] },
                childs: { ages: [], tariffIds: [] },
                roomId: null,
            });
            this.SET_GUEST_PARAMS(localGuestParams);
        },
        getGuestsQuantity(idx = null) {
            if (idx != null) {
                let item = this.guestParams[idx];
                return item.adults.quantity + item.childs.ages.length;
            }
            let guestsQuantity = 0;
            for (let item of this.guestParams) {
                guestsQuantity +=
                    item.adults.quantity + item.childs.ages.length;
            }
            return guestsQuantity;
        },
        bookingInfo() {
            const roomQuantity = this.guestParams.length;
            let guestsQuantity = this.getGuestsQuantity();
            let roomTitle = "";
            let guestTitle = "";
            if (roomQuantity === 1) {
                roomTitle = "номер";
            } else if (roomQuantity < 5) {
                roomTitle = "номера";
            } else {
                roomTitle = "номеров";
            }
            if (guestsQuantity === 1) {
                guestTitle = "гость";
            } else if (guestsQuantity < 5) {
                guestTitle = "гостя";
            } else {
                guestTitle = "гостей";
            }
            return `${roomQuantity} ${roomTitle}, ${guestsQuantity} ${guestTitle}`;
        },
        async getSpkSession() {
            //let tmpId
            const resp = await axios(this.sessionUrl, {
                method: "GET",
                headers: {
                    "X-API-Key": this.apiKey,
                    //"externalId": "1234567"
                },
            });
            return resp.data.data;
        },
        async setSpkSessionParams() {
            let data = [{
                externalId: '',

                onlSessionData: {},

                onlSessionType: "BOOKING",
            }];
            await axios
                .post(this.sessionUrl, data, {
                    headers: { Authorization: `Bearer ${this.session}` },
                })
                .catch((err) => {
                    console.log("ERROR_MESSAGE", err.response.data.message);
                });
        },
        async getSpkRoomsAndTariffs(idx) {
            //Обрезаем время иначе модуль Здравницы отдает неправильный период
            //const startDate = this.startDate.toString().split('T')[0]
            let startDate = this.$store.state.startDate;
            startDate = startDate.toString().split("T")[0];
            let getParams = {
                division: this.division,
                lang: "ru",
                date: startDate,
                days: this.$store.state.days,
                guest: this.guests[idx],
            };
            const childAges = this.guestParams[idx].childs.ages;
            if (childAges) {
                getParams.age = this.guestParams[idx].childs.ages;
            }
            const resp = await axios(this.searchUrl, {
                method: "GET",
                headers: { Authorization: `Bearer ${this.session}` },
                params: getParams,
            });
            return resp.data.data;
        },
        async searchRooms() {
            if (!this.startDate) {
                this.isDatesEmpty = true;
            }
            if (!this.division) {
                this.isSanatoriumEmpty = true;
            }
            if (this.isSanatoriumEmpty || this.isDatesEmpty) {
                return -1;
            }
            this.isStartSearching = true;
            this.SET_GUEST_PARAMS(this.guestParams);
            this.SET_GUESTS([]);
            this.SET_SEARCH_DATA([]);
            this.SET_ROOMS(null),
                this.SET_BACKEND_ROOMS(null),
                this.SET_BACKEND_TARIFFS(null),
                this.SET_TARIFFS(null);
            let spkSearchData = [];
            let tmpGuests = [];
            for (let idx = 0; idx < this.guestParams.length; idx++) {
                tmpGuests.push(this.getGuestsQuantity(idx));
                this.SET_GUESTS(tmpGuests);
                if (idx === 0) {
                    const spkSession = await this.getSpkSession();
                    this.SET_SESSION(spkSession);
                    await this.setSpkSessionParams()
                    const spkConfig = await this.getSpkConfig();
                    this.SET_CONFIG_DATA(spkConfig);
                    spkSearchData.push(await this.getSpkRoomsAndTariffs(idx));
                    this.SET_SEARCH_DATA(spkSearchData);
                    this.SET_BOOKING_INFO(this.bookingInfo());
                    continue;
                }
                spkSearchData.push(await this.getSpkRoomsAndTariffs(idx));
            }
            await this.SET_SEARCH_DATA(spkSearchData);
            this.SET_STEP(2);
            const SITE_URL = this.$store.state.parentSiteUrl;
            parent.postMessage({ pressShowRooms: '' }, SITE_URL);
        },

        getURLParams(url) {
            let urlString = new URL(url);
            const params = new URLSearchParams(urlString.search);
            let dateIn = null;
            if (params.get("date_in")) {
                dateIn = params.get("date_in");
                let partsOfDate = dateIn.split("/");
                dateIn = new Date(
                    partsOfDate[2],
                    Number(partsOfDate[1] - 1),
                    partsOfDate[0]
                ); //у месяца нужно отнять 1 так передаваемых параметрах первый месяц 01, ав JS 00
            }
            const result = {
                objectId: params.get("objectid"),
                dateIn: dateIn,
                days: params.get("days_off"),
                adults: params.get("adults"),
                children: params.get("children"),
                roomId: params.get("roomId")
            };
            return result;
        },
        handleURLParams(url) {
            const params = this.getURLParams(url);
            if (params.dateIn) {
                this.SET_START_DATE(formatDateToUTC(params.dateIn));
            }
            if (params.days) {
                this.SET_DAYS(params.days);
                const startDateInMs = Date.parse(this.startDate);
                const daysInMs = params.days * 24 * 3600 * 1000;
                const endDateinMs = startDateInMs + daysInMs;
                const endDate = new Date(endDateinMs);
                this.SET_END_DATE(formatDateToUTC(endDate));
                const endDatePrev = new Date(
                    Date.parse(endDate) - 1000 * 3600 * 24
                );
                this.SET_END_DATE_PREV(formatDateToUTC(endDatePrev));
            }
            if (params.adults) {
                this.changeAdults(0, Number(params.adults));
                this.adultsKey = Number(params.adults);
            }
            if (params.objectId == 2) {
                this.SET_DIVISION("dcb6fbcb-7009-47ff-baad-c0027817ddbc");
                this.sanatoriumKey = "dcb6fbcb-7009-47ff-baad-c0027817ddbc";
            }
            if (params.children) {
                if (params.children < 4) {
                    //ограничение, чтобы через параметры сроки не передать неадекватное значение
                    for (let idx = 0; idx < params.children; idx++) {
                        this.addChildAge(0, 10);
                    }
                }
            }
            if (params.roomId) {
                this.guestParams[0].roomId = params.roomId
                this.SET_GUEST_PARAMS(this.guestParams)
            }
        },
        async getSettings() {
            const url = `${this.backApiUrl}/settings/`;

            const resp = await axios(url, {
                method: "GET",
            });
            if (resp.data) {
                this.SET_SETTINGS(resp.data);
            }
        },
    },
};
</script>
