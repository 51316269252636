<template>
    <el-drawer
        size="820px"
        :visible.sync="expand"
        :direction="direction"
        :before-close="handleClose"
    >
        <div class="drawer-content">
            <h2>Подробное описание путевки</h2>
            <div v-if="expand">
                <span v-html="info.tariff.description"> </span>
            </div>
            
            <div
                class="footer"
                :class="{
                    'active-card': info.isChoosen,
                }"
            >
                <div class="price">
                    <div>Стоимость за {{ DAYS }} дней</div>
                    <div class="price-numbers">
                        <div class="old-price" v-if="info.tariff.discount">
                            {{ calculateOldPrice() }} ₽
                        </div>
                        <div class="actual-price">
                            <b>{{ getActualPrice() }}</b> ₽
                        </div>
                    </div>
                    <div class="sale" v-if="info.tariff.discount">
                        -{{ info.tariff.discount }}%
                    </div>
                </div>
                <div class="button-wrapper">
                    <el-button
                        class="choose"
                        type="primary"
                        @click="chooseTariff()"
                        >Выбрать</el-button
                    >
                    <el-button
                        class="choosen-button"
                        type="primary"
                        @click="goToNextTab(info.tabName, info.idx); $emit('collapseMoreInfo')"
                    >
                        <i class="las la-check"></i>
                        Далее
                    </el-button>
                </div>
            </div>
        </div>
    </el-drawer>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
    props: {
        expand: Boolean,
        info: Object,
        days: Number,
        goToNextTab: Function,
        scrollToTop: Function
    },
    data() {
        return {
            direction: "rtl",
            locExpand: this.expand,
            locInfo: this.info,
        };
    },
    computed: {
        ...mapGetters(["DAYS"]),
        
        
    },
    mounted: function () {
        this.scrollToTop();
    },
    methods: {
        ...mapActions(["SET_STEP"]),
        handleClose() {
            this.$emit("collapseMoreInfo");
        },
        chooseTariff() {
            this.$emit("chooseTariff");
            this.handleClose();
        },
        goToTheNextStep() {
            this.SET_STEP(4)
            this.$emit("collapseMoreInfo")
        },
        unChooseTariff() {
            const params = {
                tabName: this.info.tabName,
                idx: this.info.idx,
                guestAge: this.info.guestAge,
            };
            this.$emit("unChoiceTariff", params);
            this.handleClose();
        },
        calculateOldPrice() {
            let oldPrice =
                (this.info.tariff.fullPrice * 100) /
                (100 - this.info.tariff.discount);
            oldPrice = Math.ceil(oldPrice / 10) * 10;
            return oldPrice.toLocaleString("ru")
        },
        getActualPrice() {
            return this.info.tariff.fullPrice.toLocaleString("ru")
        }
    },
};
</script>
