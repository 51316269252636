<template v-if="roomInfo">
    <el-drawer
        size="820px"
        :visible.sync="visible"
        :direction="direction"
        :before-close="handlerClose"
    >
        <div class="room-full">
            <div class="room-full-top">
                <div class="column images">
                    <div class="picture">
                        <el-carousel :autoplay="false" height="230px">
                            <el-carousel-item
                                v-for="(image, imageIndex) in roomInfo.photos"
                                :key="imageIndex"
                                class="images-wrapper"
                            >
                                <div
                                    class="image"
                                    :style="{
                                        backgroundImage: 'url(' + image + ')',
                                    }"
                                    @click="handlerCarouselClick(roomInfo.id, imageIndex)"
                                ></div>
                            </el-carousel-item>
                        </el-carousel>

                        <div class="nav">
                            <div
                                v-for="(image, imageIndex) in roomInfo.photos"
                                :key="imageIndex"
                                class="image"
                                :style="{
                                    backgroundImage: 'url(' + image + ')',
                                }"
                                @click="handlerCarouselClick(roomInfo.id, imageIndex)"
                            ></div>
                        </div>
                        <div class="stickers" v-if="roomInfo.salesLeader">
                            <div class="sticker">Лидер продаж</div>
                        </div>
                    </div>
                </div>

                <div class="description column">
                    <div class="description-content">
                        <div class="title">
                            {{ roomInfo.title }}
                        </div>
                        <div class="properties">
                            <div class="last-room" v-if="roomInfo.isLast">
                                <span>Остался 1 номер</span>
                            </div>
                            <div>
                                <i class="las la-ruler-combined"></i>
                                <span>{{ roomInfo.area }} м²</span>
                            </div>
                            <div>
                                <i class="las la-user-friends"></i>
                                <span
                                    >Вместимость до
                                    {{ roomInfo.numberOfSeats }} мест</span
                                >
                            </div>
                            <div>
                                <!-- <i class="las la-child"></i> -->
                                <i class="las la-baby"></i>
                                <span
                                    >Детям до 4 лет можно без отдельного
                                    спального места</span
                                >
                            </div>
                        </div>
                        
                        <div
                            class="footer"
                            :class="{
                                'active-card':
                                    guestParams[roomInfo.idx].roomId == roomInfo.id && !roomInfo.upsell
                            }"
                        >
                            <div class="price">
                                <div class="price-numbers">
                                    <div class="actual-price">
                                        от <b>{{ roomPrice }}</b> ₽
                                    </div>
                                </div>
                            </div>
                            
                            <el-button
                                class="choose"
                                type="primary"
                                @click="choiceRoomFromDrawer"
                                v-if="roomInfo.type=='available'"
                                >Выбрать номер</el-button
                            >
                            <el-button
                                class="choosen"
                                type="primary"
                                @click="goToTheNextStep()"
                            >
                                <i class="las la-check"></i>
                                Далее
                            </el-button>
                        </div>
                    </div>
                </div>
            </div>
            
            <div class="room-full-bottom">
                <div class="column">
                    <ul class="features">
                        <li 
                        v-for="(item, index) in roomInfo.equipment"
                        :key="index">
                            <i class="las la-check"></i>{{item}}</li>
                        
                    </ul>
                </div>
                <div class="column">
                    <div v-html="roomInfo.description"></div>
                </div>
                <div
                    class="full-card-bottom-footer"
                    :class="{
                        'active-card':
                            guestParams[roomInfo.idx].roomId == roomInfo.id  && !roomInfo.upsell,
                    }"
                >
                    <div class="link" @click="handlerClose">
                        Закрыть описание
                    </div>
                    <el-button 
                        class="choose" 
                        type="primary" 
                        @click="choiceRoomFromDrawer" 
                        v-if="roomInfo.type=='available'"
                    >
                        Выбрать номер</el-button>
                    <el-button
                        class="choosen"
                        type="primary"
                        @click="goToTheNextStep()"
                    >
                        <i class="las la-check"></i>
                        Далее
                    </el-button>
                </div>
            </div>
        </div>
    </el-drawer>
</template>

<script>
import { mapState, mapActions } from "vuex";
import EventBus from '../event-bus.js'
export default {
    props: {
        expand: Boolean,
        roomInfo: {
            type: Object,
            default: function() {
                return { idx: 0 }
            } 
        },
        guestParams: Array,
        scrollToTop: Function,
        handlerCarouselClick: Function,
        choiceRoom: Function

    },
    data() {
        return {
            direction: "rtl",
            index: null,
            visible: true
        };
    },
    computed: {
        ...mapState(["step"]),
        roomPrice() {
            return this.roomInfo.minPrice.toLocaleString('ru')
        } 


    },
    methods: {
        ...mapActions(["SET_STEP"]),
        handlerClose() {
            this.$emit("collapseRoomMoreInfo");
        },
        choiceRoomFromDrawer() {
            if (this.step != 4) {
                this.choiceRoom(this.roomInfo, this.roomInfo.idx, this.roomInfo.type)
            } else {
                EventBus.$emit("upsellRoom",this.roomInfo.idx)
                this.handlerClose()
                this.scrollToTop()
            }
        },
        goToTheNextStep() {
            this.SET_STEP(3)
            this.$emit("collapseRoomMoreInfo")
        }
    },
};
</script>
